import { Box, Button, styled, Typography } from "@mui/material";

export const LoadingBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "65vh",
}));

export const FooterBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "30px",
  padding: "5px",
  width:"100%"
}));

export const SaveButton = styled(Button)(() => ({
  fontWeight: "bold",
  backgroundColor: "var(--secondary-color)",
  color: "#ffffff",
}));

export const TitleTypography = styled(Typography)(() => ({
  color: "var(--primary-color)",
  fontWeight: "bold",
}));
