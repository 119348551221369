import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { Box, TextField, useMediaQuery } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import DialogContent from "@mui/material/DialogContent";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import CloseIcon from "@mui/icons-material/Close";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import { eachDayOfInterval } from "date-fns";
import moment from "moment";

// import { formatDateSlashTo_ } from "../ReusableComponents/UtilityFunctions";
import LogoImage from "../../assets/img/Logo.jpg";
import AttendanceTable from "../AdminDashboard/Tables/AttendanceTable";
import DialogComponent from "../ReusableComponents/DialogBoxComponents/DialogComponent";
import MonthSlider from "../UpdateEmployee/UpdateAttendance/MonthSlider";
import Loader from "../Loader/Loader";
import {
  getAdminFetchData,
  getAttendenceWithFilter,
  getMonthlyLeavesData,
  getWorkingDaysInThisMonths,
} from "../../Services/AttendanceManageServices";
import { fetchConfiguration } from "../../Services/ConfigurationServices";
import usePermissions from "../RolePermission/usePermissions";
import {
  LoadingBox,
  DialogsActions,
  CustomButton,
  DescriptionIcons,
  IconButtons,
  InnerBoxOne,
  TitleDialog,
  MainBoxDialog,
  MainBox,
  DailyTableBox,
  NoRecordFoundTypography,
  MonthlyMainBox,
  DailyMainBox,
  DailyButton,
  MonthlyButton,
  ExportButton,
  MonthlyTableBox,
} from "../../Styles/AttendanceManagement/AttendanceManagementStyle";

const StyledDatePicker = styled(DatePicker)(({ theme, ismobile }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: ismobile !== "true" && "50px",
    "& fieldset": {
      border: ismobile !== "true" && "none",
    },
    "&:hover fieldset": {
      border: ismobile !== "true" && "none",
    },
    // width: ismobile === "true" && "75%",
    height: ismobile === "true" && "40px",
  },
  paddingTop: ismobile === "true" && "6px",
}));

const AttendanceManagement = ({ employeeEmpId }) => {
  let currentDate = dayjs().endOf("day");
  const { hasPermission } = usePermissions();
  const isMobile = useMediaQuery("(max-width:600px)");

  const [value, setValue] = useState(dayjs(new Date()));
  const [selectedButton, setSelectedButton] = useState("Daily");
  const [allAttendenceData, setAllAttendenceData] = useState([]);
  const [workingDaysInThisMonth, setWorkingDaysInThisMonth] = useState();
  const [employeesPresentData, setEmployeesPresentData] = useState([]);
  const [monthSelected, setMonthSelected] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const { employeeId } = useParams();
  const [exportAttendanceStartDate, setExportAttendanceStartDate] =
    useState("");
  const [exportAttendanceEndDate, setExportAttendanceEndDate] = useState("");
  // const [employeeName, setEmployeeName] = useState("");
  // const [working, setWorking] = useState(false);
  // const [employeesNameArray, setEmployeesNameArray] = useState([]);
  // const [employeeAttendanceAllData, setEmployeeAttendanceAllData] = useState([]);
  // const [employeeAttendancedata, setEmployeeAttendancedata] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const allEmployeeAttendence = useSelector(
    (state) => state.employeeData.allEmployeeAttendenceData
  );
  const selectedEmp = useSelector(
    (state) => state.addEmployee.employeeIndex.empId
  );
  const selectedEmpId = selectedEmp || employeeId;
  const [open, setOpen] = useState(false);
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const [getdata, setGetData] = useState([]);
  // const [isExportButtonDisabled, setIsExportButtonDisabled] = useState(true);
  const [empId, setEmpId] = useState(selectedEmpId);
  // const [record, setRecord] = useState([]);
  const selectedDate = useSelector((state) => state.searchData.calenderValue);
  const [date, setDate] = useState(currentDate);

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  const handleDateChange = (newValue) => {
    const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
    setValue(dayjs(formattedDate));
  };

  const memoizedAdminFetchData = useMemo(() => {
    const getAdminData = async () => {
      setIsLoading(true);
      try {
        const selectedStartdate = dayjs(value).format("DD-MM-YYYY");
        // const response = await axios.get(
        //   `${CLOUD_FUNCTIONS_ORIGIN}/admin/fetchAttendence/${selectedStartdate}`,
        //   getHeader
        // );
        const response = await getAdminFetchData(selectedStartdate);
        if (response.status === 200) {
          const data = response.data.employeeAttendenceData;
          setAllAttendenceData(data);
          setIsLoading(false);
          // dispatch(setAllWorkingData(data));
        }
      } catch (error) {
        setIsLoading(false);
        // if (error.response) {
        //   if (error.response.status === 401 || error.response.status === 403) {
        //     window.localStorage.removeItem("Token");
        //     navigate("/");
        //   }
        // }
      }
    };
    return getAdminData;
  }, [value]);

  const memoizedEmployeeFetchData = useMemo(() => {
    const getEmployeeData = async () => {
      if (exportAttendanceStartDate !== "") {
        try {
          const empId = selectedEmpId;
          // const response = await axios.get(
          //   `${CLOUD_FUNCTIONS_ORIGIN}/admin/fetchAttendanceWithFilter/${empId}?startDate=${exportAttendanceStartDate}&endDate=${exportAttendanceEndDate}`,
          //   getHeader
          // );
          const response = await getAttendenceWithFilter(
            empId,
            exportAttendanceStartDate,
            exportAttendanceEndDate
          );
          if (response.status === 200) {
            // const data = response.data.attendanceData;
            // setEmployeesNameArray(response.data.employeesNameArray);
            // const work = response.data.work;
            // const employeeName = response.data.employeeName;
            // setEmployeeName(employeeName);
            // setEmployeeAttendanceAllData(data);
            setExportAttendanceStartDate("");
            setExportAttendanceEndDate("");
            // if (work === "false") {
            //   setWorking(false);
            // } else {
            //   setWorking(true);
            // }
            // setEmployeeAttendancedata(data);
          }
        } catch (error) {
          // if (error.response) {
          //   if (
          //     error.response.status === 401 ||
          //     error.response.status === 403
          //   ) {
          //     window.localStorage.removeItem("Token");
          //     navigate("/");
          //   }
          // }
        }
      }
    };
    return getEmployeeData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportAttendanceStartDate]);

  const memoizedWorkingDaysInThisMonthData = useMemo(() => {
    const getWorkingDaysInThisMonth = async () => {
      try {
        setIsLoading(true);
        const currentDate = dayjs();
        const selectedDate = dayjs(monthSelected);
        if (selectedDate.isBefore(dayjs("2024-05-01"), "month")) {
          setIsLoading(false);
          setWorkingDaysInThisMonth([]);
          setEmployeesPresentData([]);
          return;
        }
        if (selectedDate.isAfter(currentDate, "month")) {
          setIsLoading(false);
          setWorkingDaysInThisMonth([]);
          setEmployeesPresentData([]);
          return;
        }
        const dateObject = new Date(monthSelected);
        const selectedMonth = dateObject.getMonth();
        const selectedYear = dateObject.getFullYear();
        const startOfMonth = new Date(selectedYear, selectedMonth, 1);
        const startDateFormatted = dayjs(startOfMonth).format("DD-MM-YYYY");

        const response = await getWorkingDaysInThisMonths(startDateFormatted);
        if (response.status === 200) {
          const workingDaysData = response.data.WorkingDaysInThisMonth;
          const employeesPresentData = response.data.employeesData;
          setIsLoading(false);
          setWorkingDaysInThisMonth(workingDaysData);
          setEmployeesPresentData(employeesPresentData);
        }
      } catch (error) {
        setIsLoading(false);
      }
    };
    return getWorkingDaysInThisMonth;
  }, [monthSelected]);

  const getWeekSuffix = (weekOfMonth) => {
    switch (weekOfMonth) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      case 4:
      case 5:
        return "th";
      default:
        return "";
    }
  };

  // const getWorkingSaturdays = async (year, month) => {
  //   try {
  //     const response = await fetchConfiguration();
  //     console.log("Full response:", response);

  //     const activeSaturdays = response.data.data.activeSaturdays;
  //     console.log("Active Saturdays:", activeSaturdays);

  //     if (!activeSaturdays) {
  //       console.error('Active Saturdays is undefined or null');
  //       return [];
  //     }

  //     const workingSaturdays = [];
  //     const startOfMonth = dayjs(new Date(year, month - 1, 1)).startOf('month');
  //     const endOfMonth = dayjs(new Date(year, month, 0)).endOf('month');

  //     console.log("Start of Month:", startOfMonth.format('DD-MM-YYYY'));
  //     console.log("End of Month:", endOfMonth.format('DD-MM-YYYY'));

  //     for (let date = startOfMonth; date.isBefore(endOfMonth, 'day'); date = date.add(1, 'day')) {
  //       if (date.day() === 6) {
  //         const weekOfMonth = Math.ceil(date.date() / 7);
  //         const suffix = getWeekSuffix(weekOfMonth);
  //         const saturdayKey = `${weekOfMonth}${suffix} Saturday`;
  //         console.log(`Checking date: ${date.format('DD-MM-YYYY')} - Key: ${saturdayKey} - Status:`, activeSaturdays[saturdayKey]);

  //         if (activeSaturdays[saturdayKey]) {
  //           workingSaturdays.push(date.format('DD-MM-YYYY'));
  //         }
  //       }
  //     }

  //     console.log("Final Working Saturdays:", workingSaturdays);
  //     return workingSaturdays;
  //   } catch (error) {
  //     console.error('Error fetching configuration:', error);
  //     return [];
  //   }
  // };

  const getWorkingSaturdays = async (year, month) => {
    try {
      const response = await fetchConfiguration();
      const activeSaturdays = response?.data?.data?.activeSaturdays;
      if (!activeSaturdays) {
        console.error("Active Saturdays is undefined or null");
        return [];
      }
      const workingSaturdays = [];
      const startOfMonth = moment(new Date(year, month - 1, 1)).startOf(
        "month"
      );
      const endOfMonth = moment(new Date(year, month, 0)).endOf("month");

      for (
        let date = startOfMonth.clone();
        date.isSameOrBefore(endOfMonth, "day");
        date.add(1, "day")
      ) {
        if (date.day() === 6) {
          const weekOfMonth = Math.ceil(date.date() / 7);
          const suffix = getWeekSuffix(weekOfMonth);
          const saturdayKey = `${weekOfMonth}${suffix} Saturday`;
          if (activeSaturdays[saturdayKey]) {
            workingSaturdays.push(date.format("DD-MM-YYYY"));
          }
        }
      }
      return workingSaturdays;
    } catch (error) {
      console.error("Error fetching configuration:", error);
      return [];
    }
  };

  const companyName = "Jyoti Technosoft LLP";

  const mapLeaveTypeToAbbreviation = (leaveTypeDescription) => {
    const mappings = {
      "Casual Leave": "CL",
      "Paid leave": "PL",
      "Sick leave": "SL",
      "Leave Without pay (LWP)": "LWP",
      "Religious leave": "RL",
      "UnPaid Leave": "UL",
    };
    return mappings[leaveTypeDescription] || leaveTypeDescription;
  };

  //Todo: we didn't count till 3 hours of work/day at this time. In future we can work of it.
  const handleDetailReport = async () => {
    try {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();
      const currentDayOfMonth = currentDate.getDate();
      const monthName = monthSelected.toLocaleString("en-US", {
        month: "long",
      });
      const selectedMonth = monthSelected.getMonth() + 1;
      const selectedYear = monthSelected.getFullYear();
      const startOfMonth = new Date(selectedYear, selectedMonth - 1, 1);
      const endOfMonth = new Date(selectedYear, selectedMonth, 0);
      const startDateFormatted = dayjs(startOfMonth).format("DD-MM-YYYY");
      const endDateFormatted = dayjs(endOfMonth).format("DD-MM-YYYY");
      const totalMonthlyDays = dayjs(endOfMonth).diff(startOfMonth, "day") + 1;
      const isCurrentMonth =
        selectedMonth === currentMonth + 1 && selectedYear === currentYear;

      const COLOR_SUNDAY = process.env.REACT_APP_COLOR_SUNDAY;
      const COLOR_SATURDAY = process.env.REACT_APP_COLOR_SATURDAY;
      const COLOR_LEAVE = process.env.REACT_APP_COLOR_LEAVE;
      const COLOR_HOLIDAY = process.env.REACT_APP_COLOR_HOLIDAY;
      const COLOR_DEFAULT = process.env.REACT_APP_COLOR_DEFAULT;
      // const COLOR_HALF_DAY = "FFFF00";
      // const COLOR_LIGHT_ORANGE = "FFD580";
      // const COLOR_HALF_LEAVE = process.env.REACT_APP_COLOR_HALF_LEAVE;
      // const COLOR_APPROVED = process.env.REACT_APP_COLOR_APPROVED;
      // const COLOR_UNAPPROVED = process.env.REACT_APP_COLOR_UNAPPROVED;

      const attendancePromises = getdata.map(async (employee) => {
        const empId = employee.empId;
        const response = await getAttendenceWithFilter(
          empId,
          startDateFormatted,
          endDateFormatted
        );
        return { empId, attendanceData: response.data };
      });

      const additionalResponse = await getWorkingDaysInThisMonths(
        startDateFormatted
      );
      const festivalsThisMonth = additionalResponse.data.festivalRecord.filter(
        (festival) => {
          const festivalDate = new Date(festival.date);
          return (
            festivalDate.getMonth() === monthSelected.getMonth() &&
            festivalDate.getFullYear() === selectedYear
          );
        }
      );

      const leavePromises = getdata.map(async (employee) => {
        const empId = employee.empId;
        const leaveResponse = await getMonthlyLeavesData(
          empId,
          selectedMonth,
          selectedYear
        );
        return { empId, leaveData: leaveResponse.data };
      });

      const [attendanceResults, leaveResults] = await Promise.all([
        Promise.all(attendancePromises),
        Promise.all(leavePromises),
      ]);

      const workingSaturdays = await getWorkingSaturdays(
        selectedYear,
        selectedMonth
      );

      for (let i = 0; i < attendanceResults.length; i++) {
        const { empId, attendanceData } = attendanceResults[i];
        const { leaveData } = leaveResults[i];

        if (!empId || !attendanceData || !leaveData) {
          continue;
        }

        const employeeData = additionalResponse.data.employeesData.find(
          (e) => e.empId === empId
        );

        if (!employeeData) {
          console.warn(`Employee data not found for empId: ${empId}`);
          continue;
        }

        const { employeeName } = attendanceData;
        const leavesDays = additionalResponse.data.employeesData.find(
          (employee) => employee.empId === empId
        );
        const leavesDaysEmployee = leavesDays.leavesDays;
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(`Employee_${empId}_Attendance`);

        const logoImage = workbook.addImage({
          base64: LogoImage,
          extension: "jpg",
        });

        worksheet.addImage(logoImage, {
          tl: { col: 1, row: 0 },
          ext: { width: 50, height: 40 },
        });

        const cell = worksheet.getCell("B1");
        cell.alignment = { vertical: "middle", horizontal: "center" };

        worksheet.getCell("D1").value = `${companyName}`;
        worksheet.getCell("D1").font = { bold: true, size: 16 };
        worksheet.getCell("D1").alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        worksheet.getCell(
          "D3"
        ).value = `${employeeName}'s Attendance Report - ${monthName}`;
        worksheet.getCell("D3").font = { bold: true, size: 13 };
        worksheet.getCell("D3").alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        worksheet.addRow([]);

        const headerRow = worksheet.addRow([
          "Date",
          "",
          "Check In",
          "Check Out",
          "Break Time",
          "Working Hours",
        ]);
        headerRow.font = { bold: true, size: 12 };
        headerRow.eachCell((cell) => {
          cell.alignment = { horizontal: "center" };
        });

        const columnD = worksheet.getColumn(4);
        columnD.width = 10;

        const headerRowNumber = worksheet.rowCount;
        worksheet.mergeCells(`A${headerRowNumber}:B${headerRowNumber}`);
        worksheet.addRow([]);

        const blankRowNumber = worksheet.rowCount;
        worksheet.mergeCells(`A${blankRowNumber}:F${blankRowNumber}`);
        const mergedCell = worksheet.getCell(`A${blankRowNumber}`);

        mergedCell.value = {
          richText: [
            {
              font: { strike: true },
              text: "                                                                                                                                                                                               ",
            },
          ],
        };

        mergedCell.alignment = { vertical: "middle" };

        const datesInMonth = eachDayOfInterval({
          start: startOfMonth,
          end: endOfMonth,
        });
        let weekoffDays = 0;
        let halfDayCount = 0;
        let halfDayTotalCount = 0;
        let fullDayDates = [];
        let halfDayDates = [];

        datesInMonth.forEach((date) => {
          const formattedDate = dayjs(date).format("DD-MM-YYYY");
          const attendanceRecord = attendanceData.attendanceData.find(
            (record) => record.date === formattedDate
          );

          let checkIn = "";
          let checkOut = "";
          let breakTime = "";
          let totalWorkingHours = "";
          let leaveTypeText = "";

          const leaveDetail = leaveData.leaveDetails.find(
            (leave) =>
              dayjs(leave.startDate).format("DD-MM-YYYY") === formattedDate
          );

          const holiday = festivalsThisMonth.find(
            (festival) =>
              dayjs(festival.date).format("DD-MM-YYYY") === formattedDate
          );
          const dayOfWeek = dayjs(date).day();
          // const weekOfMonth = Math.ceil(dayjs(date).date() / 7);
          // const isWorkingSaturday =
          //   dayOfWeek === 6 &&
          //   (weekOfMonth === 2 || weekOfMonth === 4 || weekOfMonth === 5);
          //  &&
          // !festivalsThisMonth.some((festival) =>
          //   dayjs(festival.date).isSame(date, "day")
          // ) &&
          // dayjs(date).isBefore(dayjs(), "day");

          const isWorkingSaturday =
            dayjs(date).day() === 6 &&
            workingSaturdays?.includes(formattedDate) &&
            !festivalsThisMonth?.some(
              (festival) =>
                dayjs(festival?.date).format("DD-MM-YYYY") === formattedDate
            );

          if (attendanceRecord) {
            checkIn = formatTime(attendanceRecord.dailytimer.TimerStart);
            checkOut = formatTime(attendanceRecord.dailytimer.TimerEnd);
            breakTime = formatDuration(attendanceRecord.dailytimer.BreakTime);
            totalWorkingHours = formatDuration(
              attendanceRecord.dailytimer.TotalTime
            );
          } else {
            if (leaveDetail) {
              leaveTypeText = leaveDetail.leaveType.leaveType;
            } else if (holiday) {
              leaveTypeText = holiday.festival;
            } else {
              if (dayOfWeek === 0) {
                leaveTypeText = "Sunday";
                weekoffDays++;
              }
              else if (!isWorkingSaturday && dayOfWeek === 6) {
                leaveTypeText = "Saturday";
                weekoffDays++;
              }
              else {
                if (dayjs(date).date() > currentDayOfMonth) {
                  return;
                }
                leaveTypeText = "";
              }

              // else if (dayOfWeek === 6) {
              //   if (weekOfMonth === 1 || weekOfMonth === 3) {
              //     leaveTypeText = "Saturday";
              //     weekoffDays++;
              //   }
              // } else {
              //   if (dayjs(date).date() > currentDayOfMonth) {
              //     return;
              //   }
              //   leaveTypeText = "";
              // }
            }
          }

          // if (!totalWorkingHours && !leaveTypeText === "Sunday" && !leaveTypeText === "Saturday" && !holiday) {
          //   return;          
          // }

          worksheet.addRow([
            formattedDate,
            "",
            checkIn,
            checkOut,
            breakTime,
            totalWorkingHours,
          ]).alignment = {
            horizontal: "center",
          };
          const currentRowNumber = worksheet.rowCount;
          worksheet.mergeCells(`A${currentRowNumber}:B${currentRowNumber}`);

          const rowNumber = worksheet.rowCount;

          let fillColor = { argb: COLOR_DEFAULT };

          const [hours, minutes] = totalWorkingHours.split(":").map(Number);
          const totalHours = hours + minutes / 60;
          if (
            totalHours < 3 &&
            !isWorkingSaturday &&
            festivalsThisMonth.length === 0
          ) {
            fillColor = { argb: "90EE90" };
            fullDayDates.push({
              date: formattedDate,
              reason: "Full Day Leave",
              leaveType: "LWP",
            });
          } else if (totalHours >= 3.75 && totalHours < 6) {
            fillColor = { argb: "FFFF00" };
            halfDayTotalCount += 0.5;
            halfDayDates.push({
              date: formattedDate,
              reason: "Half Day Leave",
              leaveType: "LWP",
            });
          } else if (totalHours >= 7.5 && totalHours <= 8) {
            fillColor = { argb: "FF474C" };
          } else if (leaveTypeText === "Sunday") {
            fillColor = { argb: COLOR_SUNDAY };
          } else if (leaveTypeText === "Saturday") {
            fillColor = { argb: COLOR_SATURDAY };
          } else if (leaveDetail) {
            fillColor = { argb: COLOR_LEAVE };
          } else if (holiday) {
            fillColor = { argb: COLOR_HOLIDAY };
          } else if (leaveTypeText === "LWP") {
            fillColor = { argb: COLOR_LEAVE };
          } else if (totalHours >= 6 && totalHours < 7.5) {
            fillColor = { argb: "FFD580" };
            halfDayCount++;
          } else {
            fillColor = { argb: COLOR_DEFAULT };
          }

          if (
            halfDayCount > 2 &&
            totalHours >= 6 &&
            totalHours < 7.5 &&
            !isWorkingSaturday
          ) {
            fillColor = { argb: "FFFF00" };
            halfDayTotalCount += 0.5;
            halfDayDates.push({
              date: formattedDate,
              reason: "Half Day Leave",
              leaveType: "LWP",
            });
          } else if (
            halfDayCount <= 2 &&
            totalHours >= 6 &&
            totalHours < 7.5 &&
            !isWorkingSaturday
          ) {
            fillColor = { argb: "FFD580" };
          }

          if (leaveDetail && leaveDetail.duration === "0.5") {
            fillColor = { argb: "FFFF00" };
          }
          for (let col = 2; col <= 6; col++) {
            worksheet.getCell(
              `${String.fromCharCode(64 + col)}${rowNumber}`
            ).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: fillColor,
            };
          }

          if (leaveTypeText) {
            const abbreviation = mapLeaveTypeToAbbreviation(leaveTypeText);
            worksheet.getCell(`C${rowNumber}`).value = abbreviation;
            worksheet.getCell(`C${rowNumber}`).font = { bold: true };
            worksheet.getCell(`C${rowNumber}`).alignment = {
              horizontal: "center",
            };
            worksheet.mergeCells(`C${rowNumber}:F${rowNumber}`);
          }

          if (holiday) {
            worksheet.getCell(`C${rowNumber}`).value = holiday.festival;
            worksheet.getCell(`C${rowNumber}`).font = { bold: true };
            worksheet.getCell(`C${rowNumber}`).alignment = {
              horizontal: "center",
            };
          }
        });

        worksheet.columns.forEach((column, index) => {
          if (index === 2 || index === 3 || index === 4 || index === 5) {
            column.width = 16;
          } else if (index === 0) {
            column.width = 5;
          } else if (index === 1) {
            column.width = 14;
          }
        });

        worksheet.addRow([]);

        const adjustedPresentDays =
          employeeData.presentDays - halfDayTotalCount;

        worksheet.addRow(["Total Present Days"]).font = {
          bold: true,
          size: 11,
        };
        worksheet.mergeCells(`A${worksheet.rowCount}:B${worksheet.rowCount}`);
        worksheet.getCell(`D${worksheet.rowCount}`).value = adjustedPresentDays;
        worksheet.getCell(`D${worksheet.rowCount}`).font = {
          bold: true,
          size: 11,
        };
        var remainingDays = employeeData.workingdays - currentDayOfMonth;
        if (isCurrentMonth) {
          if (remainingDays > 0) {
            worksheet.addRow(["Remaining Days"]).font = {
              bold: true,
              size: 11,
            };
            worksheet.mergeCells(
              `A${worksheet.rowCount}:B${worksheet.rowCount}`
            );
            worksheet.getCell(`D${worksheet.rowCount}`).value = remainingDays;
            worksheet.getCell(`D${worksheet.rowCount}`).font = {
              bold: true,
              size: 11,
            };
            if (leavesDaysEmployee === 0 && festivalsThisMonth.length === 0) {
              worksheet.getCell(`D${worksheet.rowCount}`).border = {
                bottom: { style: "thin" },
              };
            }
          }
        }
        if (
          remainingDays === 0 &&
          leavesDaysEmployee === 0 &&
          festivalsThisMonth.length === 0
        ) {
          worksheet.getCell(`D${worksheet.rowCount}`).border = {
            bottom: { style: "thin" },
          };
        }

        const leaveDetails = leaveData.leaveDetails;

        if (leavesDaysEmployee > 0) {
          worksheet.addRow(["Total Leaves"]).font = { bold: true, size: 11 };
          worksheet.mergeCells(`A${worksheet.rowCount}:B${worksheet.rowCount}`);
          worksheet.getCell(`D${worksheet.rowCount}`).value =
            leavesDaysEmployee + halfDayTotalCount;
          worksheet.getCell(`D${worksheet.rowCount}`).font = {
            bold: true,
            size: 11,
          };
          if (festivalsThisMonth.length === 0) {
            worksheet.getCell(`D${worksheet.rowCount}`).border = {
              bottom: { style: "thin" },
            };
          }
        }

        if (festivalsThisMonth.length > 0) {
          worksheet.addRow(["Public Holidays"]).font = { bold: true, size: 11 };
          worksheet.mergeCells(`A${worksheet.rowCount}:B${worksheet.rowCount}`);
          worksheet.getCell(`D${worksheet.rowCount}`).value =
            festivalsThisMonth.length;
          worksheet.getCell(`D${worksheet.rowCount}`).font = {
            bold: true,
            size: 11,
          };
          worksheet.getCell(`D${worksheet.rowCount}`).border = {
            bottom: { style: "thin" },
          };
        }

        worksheet.addRow(["Total Working Days"]).font = {
          bold: true,
          size: 11,
        };
        worksheet.getCell(`E${worksheet.rowCount}`).border = {
          top: { style: "thin" },
        };
        worksheet.mergeCells(`A${worksheet.rowCount}:B${worksheet.rowCount}`);
        worksheet.getCell(`E${worksheet.rowCount}`).value =
          employeeData.workingdays;
        worksheet.getCell(`E${worksheet.rowCount}`).font = {
          bold: true,
          size: 11,
        };

        worksheet.addRow(["Weekoff Days"]).font = { bold: true, size: 11 };
        worksheet.mergeCells(`A${worksheet.rowCount}:B${worksheet.rowCount}`);
        worksheet.getCell(`E${worksheet.rowCount}`).value = weekoffDays;
        worksheet.getCell(`E${worksheet.rowCount}`).font = {
          bold: true,
          size: 11,
        };
        worksheet.getCell(`E${worksheet.rowCount}`).border = {
          bottom: { style: "thin" },
        };

        worksheet.addRow(["Total Monthly Days"]).font = {
          bold: true,
          size: 11,
        };
        worksheet.mergeCells(`A${worksheet.rowCount}:B${worksheet.rowCount}`);
        worksheet.getCell(`F${worksheet.rowCount}`).value = totalMonthlyDays;
        worksheet.getCell(`F${worksheet.rowCount}`).font = {
          bold: true,
          size: 11,
        };
        worksheet.getCell(`F${worksheet.rowCount}`).border = {
          top: { style: "thin" },
        };

        const formatDate = (date1) => {
          const dateString = date1.split("T");
          const [year, month, day] = dateString[0].split("-");
          const date = new Date(year, month - 1, day);
          const dayFormatted = String(date.getDate()).padStart(2, "0");
          const monthFormatted = String(date.getMonth() + 1).padStart(2, "0");
          const yearFormatted = date.getFullYear();
          return `${dayFormatted}/${monthFormatted}/${yearFormatted}`;
        };
        // if (leaveDetails && leaveDetails.length > 0) {
        //   worksheet.addRow([]);
        //   worksheet.addRow([]);
        //   worksheet.addRow([]);
        //   worksheet.addRow(["Leave Details"]).font = { bold: true, size: 11 };
        //   let totalLeaveDuration = 0;
        //   let hasHalfDayLeave = false;
        //   const addedDates = new Set();

        //   leaveDetails.forEach((leave) => {
        //     const abbreviation = mapLeaveTypeToAbbreviation(
        //       leave.leaveType.leaveType
        //     );
        //     const isHalfDayLeave = leave.duration === "0.5";
        //     const startDateFormatted = formatDate(leave.startDate);
        //     const endDateFormatted = formatDate(leave.endDate);
        //     worksheet.addRow([
        //       leave.reason,
        //       "",
        //       "",
        //       startDateFormatted,
        //       endDateFormatted,
        //       abbreviation,
        //     ]);
        //     const currentRow = worksheet.rowCount;
        //     worksheet.mergeCells(`A${currentRow}:C${currentRow}`);
        //     worksheet.getCell(`A${currentRow}`).alignment = {
        //       wrapText: true,
        //     };
        //     worksheet.getCell(`D${currentRow}`).alignment = {
        //       vertical: "top",
        //       horizontal: "center",
        //     };
        //     worksheet.getCell(`E${currentRow}`).alignment = {
        //       vertical: "top",
        //       horizontal: "center",
        //     };
        //     worksheet.getCell(`F${currentRow}`).alignment = {
        //       vertical: "top",
        //       horizontal: "center",
        //     };
        //     totalLeaveDuration = totalLeaveDuration + parseInt(leave.duration);

        //     let fillColor;
        //     if (leave.status === "Approved") {
        //       fillColor = { argb: "90EE90" };
        //     } else if (
        //       leave.status === "Rejected" ||
        //       leave.status === "Cancelled" ||
        //       leave.status === "Pending"
        //     ) {
        //       fillColor = { argb: COLOR_LEAVE };
        //     }

        //     if (isHalfDayLeave) {
        //       fillColor = { argb: "FFFF00" };
        //        hasHalfDayLeave = true;
        //     }
        //     for (let col = 0; col <= 5; col++) {
        //       worksheet.getCell(
        //         `${String.fromCharCode(65 + col)}${worksheet.rowCount}`
        //       ).fill = {
        //         type: "pattern",
        //         pattern: "solid",
        //         fgColor: fillColor,
        //       };
        //     }
        //   });
        //   let fillColor;
        //   if (halfDayDates.length > 0) {
        //     halfDayDates.forEach((entry) => {
        //       const formattedDate = entry.date.replace(/-/g, "/");
        //       if (!addedDates.has(formattedDate)) {
        //       const rowNumber = worksheet.rowCount + 1;
        //       worksheet.addRow([
        //         entry.reason,
        //         "",
        //         "",
        //         formattedDate,
        //         formattedDate,
        //         entry.leaveType,
        //       ]);
        //       fillColor = { argb: "FFFF00" };
        //       for (let col = 1; col <= 6; col++) {
        //         worksheet.getCell(
        //           `${String.fromCharCode(64 + col)}${rowNumber}`
        //         ).fill = {
        //           type: "pattern",
        //           pattern: "solid",
        //           fgColor: fillColor,
        //         };
        //       }
        //       worksheet.mergeCells(`A${rowNumber}:C${rowNumber}`);
        //       worksheet.getCell(`A${rowNumber}`).alignment = {
        //         wrapText: true,
        //       };
        //       worksheet.getCell(`D${rowNumber}`).alignment = {
        //         vertical: "top",
        //         horizontal: "center",
        //       };
        //       worksheet.getCell(`E${rowNumber}`).alignment = {
        //         vertical: "top",
        //         horizontal: "center",
        //       };
        //       worksheet.getCell(`F${rowNumber}`).alignment = {
        //         vertical: "top",
        //         horizontal: "center",
        //       };
        //      }
        //     });
        //   }
        // }
        if (leaveDetails && leaveDetails.length > 0) {
          worksheet.addRow([]);
          worksheet.addRow([]);
          worksheet.addRow([]);
          worksheet.addRow(["Leave Details"]).font = { bold: true, size: 11 };
          let totalLeaveDuration = 0;
          const addedDates = new Set();
          let fillColor;

          leaveDetails.forEach((leave) => {
            const abbreviation = mapLeaveTypeToAbbreviation(
              leave.leaveType.leaveType
            );
            const isHalfDayLeave = leave.duration === "0.5";
            const startDateFormatted = formatDate(leave.startDate);
            const endDateFormatted = formatDate(leave.endDate);

            worksheet.addRow([
              leave.reason,
              "",
              "",
              startDateFormatted,
              endDateFormatted,
              abbreviation,
            ]);

            const currentRow = worksheet.rowCount;
            worksheet.mergeCells(`A${currentRow}:C${currentRow}`);
            worksheet.getCell(`A${currentRow}`).alignment = {
              wrapText: true,
            };
            worksheet.getCell(`D${currentRow}`).alignment = {
              vertical: "top",
              horizontal: "center",
            };
            worksheet.getCell(`E${currentRow}`).alignment = {
              vertical: "top",
              horizontal: "center",
            };
            worksheet.getCell(`F${currentRow}`).alignment = {
              vertical: "top",
              horizontal: "center",
            };

            totalLeaveDuration += parseInt(leave.duration);

            if (leave.status === "Approved") {
              fillColor = { argb: "90EE90" };
            } else if (
              leave.status === "Rejected" ||
              leave.status === "Cancelled" ||
              leave.status === "Pending"
            ) {
              fillColor = { argb: COLOR_LEAVE };
            }

            if (isHalfDayLeave) {
              fillColor = { argb: "FFFF00" };
            }

            for (let col = 0; col <= 5; col++) {
              worksheet.getCell(
                `${String.fromCharCode(65 + col)}${currentRow}`
              ).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: fillColor,
              };
            }

            addedDates.add(startDateFormatted);
            addedDates.add(endDateFormatted);
          });

          if (fullDayDates.length > 0) {
            fullDayDates.forEach((entry) => {
              const formattedDate = entry.date.replace(/-/g, "/");

              if (!addedDates.has(formattedDate)) {
                const rowNumber = worksheet.rowCount + 1;
                worksheet.addRow([
                  entry.reason,
                  "",
                  "",
                  formattedDate,
                  formattedDate,
                  entry.leaveType,
                ]);

                fillColor = { argb: "90EE90" };
                for (let col = 1; col <= 6; col++) {
                  worksheet.getCell(
                    `${String.fromCharCode(64 + col)}${rowNumber}`
                  ).fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: fillColor,
                  };
                }

                worksheet.mergeCells(`A${rowNumber}:C${rowNumber}`);
                worksheet.getCell(`A${rowNumber}`).alignment = {
                  wrapText: true,
                };
                worksheet.getCell(`D${rowNumber}`).alignment = {
                  vertical: "top",
                  horizontal: "center",
                };
                worksheet.getCell(`E${rowNumber}`).alignment = {
                  vertical: "top",
                  horizontal: "center",
                };
                worksheet.getCell(`F${rowNumber}`).alignment = {
                  vertical: "top",
                  horizontal: "center",
                };

                addedDates.add(formattedDate);
              }
            });
          }

          if (halfDayDates.length > 0) {
            halfDayDates.forEach((entry) => {
              const formattedDate = entry.date.replace(/-/g, "/");

              if (!addedDates.has(formattedDate)) {
                const rowNumber = worksheet.rowCount + 1;
                worksheet.addRow([
                  entry.reason,
                  "",
                  "",
                  formattedDate,
                  formattedDate,
                  entry.leaveType,
                ]);

                fillColor = { argb: "FFFF00" };
                for (let col = 1; col <= 6; col++) {
                  worksheet.getCell(
                    `${String.fromCharCode(64 + col)}${rowNumber}`
                  ).fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: fillColor,
                  };
                }

                worksheet.mergeCells(`A${rowNumber}:C${rowNumber}`);
                worksheet.getCell(`A${rowNumber}`).alignment = {
                  wrapText: true,
                };
                worksheet.getCell(`D${rowNumber}`).alignment = {
                  vertical: "top",
                  horizontal: "center",
                };
                worksheet.getCell(`E${rowNumber}`).alignment = {
                  vertical: "top",
                  horizontal: "center",
                };
                worksheet.getCell(`F${rowNumber}`).alignment = {
                  vertical: "top",
                  horizontal: "center",
                };

                addedDates.add(formattedDate);
              }
            });
          }
        }

        if (festivalsThisMonth.length > 0) {
          worksheet.addRow([]);
          worksheet.addRow([]);
          worksheet.addRow([]);
          worksheet.addRow(["Holiday Details"]).font = { bold: true, size: 11 };
          festivalsThisMonth.forEach((festival) => {
            worksheet.addRow([
              festival.festival,
              "",
              "",
              formatDate(festival.date),
            ]);
            worksheet.mergeCells(
              `A${worksheet.rowCount}:C${worksheet.rowCount}`
            );
            worksheet.getCell(`A${worksheet.rowCount}`).alignment = {
              wrapText: true,
            };
          });
        }

        worksheet.addRow([]);

        if (
          leaveData.leaveDetails.length === 0 &&
          festivalsThisMonth.length === 0
        ) {
          worksheet.addRow([]);
          worksheet.addRow([]);
          worksheet.addRow([]);
        }

        worksheet.addRow(["Short Name", "", "Full Name"]).font = { bold: true };
        worksheet.mergeCells(`A${worksheet.rowCount}:B${worksheet.rowCount}`);

        const leaveTypes = [
          { fullName: "Casual Leave", shortForm: "CL" },
          { fullName: "Paid leave", shortForm: "PL" },
          { fullName: "Sick leave", shortForm: "SL" },
          { fullName: "Leave Without pay (LWP)", shortForm: "LWP" },
          { fullName: "Religious leave", shortForm: "RL" },
          { fullName: "UnPaid Leave", shortForm: "UL" },
        ];

        leaveTypes.forEach(({ fullName, shortForm }) => {
          worksheet.addRow([shortForm, "", fullName]);
          worksheet.mergeCells(`A${worksheet.rowCount}:B${worksheet.rowCount}`);
        });
        worksheet.addRow([]);
        worksheet.addRow(["Status", "", "Color"]).font = { bold: true };
        worksheet.mergeCells(`A${worksheet.rowCount}:B${worksheet.rowCount}`);
        const approvedRow = worksheet.addRow(["Approved", ""]);
        worksheet.mergeCells(`A${worksheet.rowCount}:B${worksheet.rowCount}`);
        approvedRow.getCell(3).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "90EE90" },
        };

        const unapprovedRow = worksheet.addRow(["Unapproved", ""]);
        worksheet.mergeCells(`A${worksheet.rowCount}:B${worksheet.rowCount}`);
        unapprovedRow.getCell(3).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: COLOR_LEAVE },
        };

        const halfLeaveRow = worksheet.addRow(["Half Leave", ""]);
        worksheet.mergeCells(`A${worksheet.rowCount}:B${worksheet.rowCount}`);
        halfLeaveRow.getCell(3).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF00" },
        };

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(
          blob,
          `${employeeName}'s Attendance Report and Leaves - ${monthName}.xlsx`
        );
      }
      setOpen(false);
      setSelectedRows([]);
      setGetData([]);
      // setIsExportButtonDisabled(true);
    } catch (error) {
      console.error("Error exporting detail report:", error);
    }
  };

  function formatTime(timestamp) {
    if (!timestamp) return "00:00:00";
    const date = new Date(parseInt(timestamp));
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const meridiem = hours >= 12 ? "PM" : "AM";
    const formattedHours = (hours % 12 || 12).toString().padStart(2, "0");

    return `${formattedHours}:${minutes}:${seconds} ${meridiem}`;
  }

  function formatDuration(milliseconds) {
    if (!milliseconds) return "00:00:00";
    const hours = Math.floor(milliseconds / (1000 * 60 * 60));
    const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  }

  const handleRowSelect = (employee) => {
    const empId = employee.empId;
    if (selectedRows.includes(empId)) {
      const updatedSelectedRows = selectedRows.filter((id) => id !== empId);
      setSelectedRows(updatedSelectedRows);
      setGetData((prevData) => prevData.filter((item) => item.empId !== empId));
    } else {
      setSelectedRows([...selectedRows, empId]);
      const filteredData = employeesPresentData.filter(
        (item) => item.empId === empId
      );
      setGetData((prevData) => [...prevData, ...filteredData]);
    }
    // setIsExportButtonDisabled(selectedRows.length === 0);
  };

  const handleExportClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRows([]);
  };

  const handleSummaryReport = () => {
    alert("Hey! Shortly Avaliable");
    handleClose();
  };

  const renderForm = () => {
    return (
      <>
        <MainBoxDialog isdarktheme={isDarkTheme ? "true" : "false"}>
          <TitleDialog isdarktheme={isDarkTheme ? "true" : "false"}>
            <InnerBoxOne>
              <ListItemAvatar>
                <DescriptionIcons
                  isdarktheme={isDarkTheme ? "true" : "false"}
                />
              </ListItemAvatar>
              <span>Select Report Type</span>
            </InnerBoxOne>
            <IconButtons
              onClick={handleClose}
              isdarktheme={isDarkTheme ? "true" : "false"}
            >
              <CloseIcon />
            </IconButtons>
          </TitleDialog>
          <DialogContent>
            <DialogsActions>
              <CustomButton
                onClick={handleSummaryReport}
                variant="contained"
                color="success"
                isdarktheme={isDarkTheme ? "true" : "false"}
              >
                Summary Report
              </CustomButton>
              <CustomButton
                onClick={handleDetailReport}
                variant="contained"
                color="success"
                isdarktheme={isDarkTheme ? "true" : "false"}
              >
                Detail Report
              </CustomButton>
            </DialogsActions>
          </DialogContent>
        </MainBoxDialog>
      </>
    );
  };

  useEffect(() => {
    memoizedEmployeeFetchData();
    memoizedWorkingDaysInThisMonthData();
  }, [memoizedEmployeeFetchData, memoizedWorkingDaysInThisMonthData]);

  useEffect(() => {
    memoizedAdminFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  // useEffect(() => {
  //   memoizedAdminFetchData();
  // }, [memoizedAdminFetchData, value]);

  // useEffect(() => {
  //   memoizedWorkingDaysInThisMonthData();
  // }, [memoizedWorkingDaysInThisMonthData]);

  // useEffect(() => {
  //   setIsExportButtonDisabled(selectedRows.length === 0);
  // }, [selectedRows]);
  // Define the custom icon component for mobile view

  useEffect(() => {
    setDate(selectedDate);
    setEmpId(selectedEmpId);
    // const filteredDataByEmployee = allEmployeeAttendence?.filter(
    //   (item) => item?.empId === empId
    // );
    // if (filteredDataByEmployee?.length !== 0) {
    //   const filteredDataBydate = filteredDataByEmployee[0]?.records?.filter(
    //     (item) => item?.date === formatDateSlashTo_(date)
    //   );
    //   const adminShowData = filteredDataBydate[0]?.dailytimer?.timer;
    //   setRecord(adminShowData);
    //   if (filteredDataBydate?.length === 0) {
    //     setRecord([]);
    //   } else {
    //     setRecord(adminShowData);
    //   }
    // }
  }, [selectedDate, selectedEmpId, date, empId, allEmployeeAttendence]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {isLoading ? (
        <LoadingBox>
          <Loader />
        </LoadingBox>
      ) : (
        <Box>
          <Box sx={{ margin: "0px 0px" }}>
            <MainBox bottom={0}>
              <Box sx={{ display: "flex" }}>
                {hasPermission("Daily") && (
                  <DailyButton
                    onClick={() => handleButtonClick("Daily")}
                    selectedbutton={selectedButton}
                  >
                    Daily
                  </DailyButton>
                )}
                {hasPermission("Monthly") && (
                  <MonthlyButton
                    onClick={() => handleButtonClick("Monthly")}
                    selectedbutton={selectedButton}
                  >
                    Monthly
                  </MonthlyButton>
                )}
              </Box>
              {/* {getdata.length > 0 && ( */}
              {selectedRows.length > 0 && (
                <ExportButton variant="contained" onClick={handleExportClick}>
                  EXPORT
                </ExportButton>
              )}
              <DialogComponent
                open={open}
                onClose={handleClose}
                renderChildern={renderForm}
              />
            </MainBox>
            {hasPermission("Daily") && selectedButton === "Daily" ? (
              <>
                <DailyMainBox ismobile={isMobile ? "true" : "false"}>
                  {dayjs(value).format("DD-MM-YYYY") ===
                    dayjs(new Date()).format("DD-MM-YYYY")
                    ? `Today (${value.format("DD-MM-YYYY")})`
                    : dayjs(value).format("DD-MM-YYYY") ===
                      dayjs(new Date()).subtract(1, "day").format("DD-MM-YYYY")
                      ? `Yesterday (${value.format("DD-MM-YYYY")})`
                      : value.format("DD-MM-YYYY")}

                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="en-gb"
                  >
                    <StyledDatePicker
                      sx={{
                        ".MuiInputBase-input": {
                          display: !isMobile && "none",
                          visibility: !isMobile && "hidden",
                        },
                      }}
                      ismobile={isMobile ? "true" : "false"}
                      slots={{
                        openPickerIcon: EditCalendarIcon,
                      }}
                      slotProps={{
                        openPickerButton: {
                          color: "primary",
                        },
                      }}
                      maxDate={currentDate}
                      onChange={(date) => {
                        handleDateChange(date);
                      }}
                      value={value}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </DailyMainBox>
                <DailyTableBox>
                  <Box overflow="auto" sx={{ paddingTop: "8px" }}>
                    <AttendanceTable
                      allAttendenceData={allAttendenceData}
                      employeeLogin={false}
                      adminLogin={false}
                      attendanceManagement={false}
                      attendanceManagementDaily={true}
                      workingDaysInThisMonth={workingDaysInThisMonth}
                    // employeesPresentData={employeesPresentData}
                    />
                  </Box>
                </DailyTableBox>
              </>
            ) : null}
            {hasPermission("Monthly") && selectedButton === "Monthly" ? (
              <>
                <MonthlyMainBox>
                  {/* {currentMonthYear}
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="en-gb"
                  >
                    <StyledDatePicker
                      sx={{
                        ".MuiInputBase-input": {
                          display: "none",
                        },
                      }}
                      slots={{
                        openPickerIcon: EditCalendarIcon,
                      }}
                      slotProps={{
                        openPickerButton: {
                          color: "primary",
                        },
                      }}
                      maxDate={currentDate}
                      onChange={(date) => {
                        handleDateChange(date);
                      }}
                      value={value}
                    />
                  </LocalizationProvider>
                </Box>
                <Box
                  sx={{
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
                    borderRadius: "5px",
                    background: "var(--background-table-sidebar-card-color)",
                    padding: "0px 10px 2px 10px",
                    marginBottom: "20px",
                  }}
                >
                  <Box overflow="auto" sx={{ paddingTop: "8px" }}>
                    <AttendanceTable
                      allAttendenceData={allAttendenceData}
                      employeeLogin={false}
                      adminLogin={false}
                      attendanceManagement={false}
                      attendanceManagementDaily={true}
                      workingDaysInThisMonth={workingDaysInThisMonth}
                      employeesPresentData={employeesPresentData}
                    />
                  </Box>
                </Box>
              </>
            ) : null}
            {selectedButton === "Monthly" ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "8px",
                    marginBottom: "8px",
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
                    borderRadius: "5px",
                    background: "var(--background-table-sidebar-card-color)",
                    color: "var(--secondary-text-color)",
                    flexDirection: "column",
                  }}
                >
                  {/* {currentMonthYear}
            </Box>  */}
                  {/* <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: 'var(--primary-highlight-color)' }}> */}
                  <MonthSlider
                    isMobile={isMobile}
                    setMonth={setMonthSelected}
                    month={monthSelected}
                  />
                </MonthlyMainBox>
                {employeesPresentData.length > 0 ? (
                  <MonthlyTableBox>
                    <Box overflow="auto" sx={{ paddingTop: "8px" }}>
                      <AttendanceTable
                        allAttendenceData={allAttendenceData}
                        employeeLogin={false}
                        adminLogin={false}
                        attendanceManagement={true}
                        workingDaysInThisMonth={workingDaysInThisMonth}
                        employeesPresentData={employeesPresentData}
                        selectedRows={selectedRows}
                        handleRowSelect={handleRowSelect}
                        startDate={exportAttendanceStartDate}
                        endDate={exportAttendanceEndDate}
                        setSelectedRows={setSelectedRows}
                        setGetData={setGetData}
                      />
                    </Box>
                  </MonthlyTableBox>
                ) : (
                  <NoRecordFoundTypography>
                    No records found.
                  </NoRecordFoundTypography>
                )}
              </>
            ) : null}
          </Box>
        </Box>
      )}
    </LocalizationProvider>
  );
};

export default AttendanceManagement;
