import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Zoom,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { Edit, WarningAmber } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  viewLeaveDetail,
  viewProfileSubtitle,
  viewProfileTitle,
} from "../../ReusableComponents/CustomDesignMUI/CustomMUI";
import { setAllLeaveData } from "../../../Slices/leaveData";
import {
  setToast,
  setToastMsg,
  setToastType,
} from "../../../Slices/toastSlice";
import {
  setDetailLeave,
  setOnLeaveManagement,
  setUpdatedLeaveType,
} from "../../../Slices/selectedActive";
import UpdateLeave from "../../UpdateEmployee/UpdateLeave/UpdateLeave";
import Loader from "../../Loader/Loader";
import DialogComponent from "../../ReusableComponents/DialogBoxComponents/DialogComponent";
import { errorMessage } from "../../../Services/axiosInstance";
import { upsertApproveLeave, upsertCancelLeave, upsertRejectLeave } from "../../../Services/LeaveManageServices";
import { getEmpDetails } from "../../../Services/EmployeeManageServices";
import { ViewLeaveLoadingBox, CustomTextField, DialogTitles, CustomButton, CancelButton, FooterBox, ErrorTypography, MainBox, InnerBoxOne, CustomTypography, WarningMainBox, WarningTypography, WarningDetailBox } from "../../../Styles/LeaveManagement/ViewLeaveDetailStyle";

const ViewLeaveDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const user = useSelector((state) => state.selectedActiveView.role);
  const adminData = useSelector((state) => state.adminData.adminData);
  const updatedLeaveType = useSelector(
    (state) => state.selectedActiveView.updatedLeaveType
  );
  const leaveData = useSelector(
    (state) => state.selectedActiveView.leaveDetail
  );

  const [backFromUpdate, setBackFromUpdate] = useState(true);
  const [displayError, setDisplayError] = useState("");
  const [employeeData, setEmployeeData] = useState();
  const [warningArray, setWarningArray] = useState();
  const [openApprovalDialog, setOpenApprovalDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [leaveDetail, setLeaveDetail] = useState(leaveData || "");
  const [duration, setDuration] = useState(leaveDetail.duration);
  const [isLoading, setIsLoading] = useState(true);
  const [comment, setComment] = useState();
  const [error, setError] = useState();

  const token = window.localStorage.getItem("Token");
  const leaveStartDate = new Date(leaveDetail.startDate);
  const today = new Date();
  const isEditDeleteVisible = today < leaveStartDate;
  const EmployeeLogIn = user === "employee";
  const adminLogin = user === "admin";
  const isCEO = adminData.position?.role === "CEO";
  const employeeId = leaveData?.empId;
  const leaveId = leaveDetail?._id;

  const handleCommentChange = (event) => {
    setError("");
    setComment(event.target.value);
  };

  const handleCloseApprovalDialog = async () => {
    setOpenApprovalDialog(false);
  };

  const handleApprove = async () => {
    handleCloseApprovalDialog();
  };

  const handleOpenCancelDialog = () => {
    setOpenCancelDialog(true);
  };

  const handleCloseCancelDialog = async () => {
    setOpenCancelDialog(false);
  };

  const handleCancel = () => {
    handleCloseCancelDialog();
  };

  const handleOpenRejectDialog = async () => {
    setOpenRejectDialog(true);
  };

  const handleCloseRejectDialog = async () => {
    setOpenRejectDialog(false);
  };

  const handleReject = () => {
    handleCloseRejectDialog();
  };

  const handleOpenApprovalDialog = () => {
    setOpenApprovalDialog(true);
  };

  const openUpdateLeave = () => {
    dispatch(setOnLeaveManagement("true"));
    setBackFromUpdate(false);
  };

  const onBackClick = () => {
    if (EmployeeLogIn) {
      navigate("/leaves");
    }
    if (adminLogin) {
      navigate("/leave-management");
    }
  };

  const capitalizeFirstLetter = (string) => {
    if (string && string?.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
      return string;
    }
  };

  function backToView(leaveData) {
    dispatch(setDetailLeave(leaveData));
    setLeaveDetail(leaveData);
    setBackFromUpdate(true);
    memoizedleaveWarning();
    time(leaveData);
  }

  const formatDate = (date1) => {
    const dateString = date1?.split("T");
    const [year, month, day] = dateString[0]?.split("-");
    const date = new Date(year, month - 1, day);
    const dayFormatted = String(date.getDate()).padStart(2, "0");
    const monthFormatted = String(date.getMonth() + 1).padStart(2, "0");
    const yearFormatted = date?.getFullYear();
    return `${dayFormatted}/${monthFormatted}/${yearFormatted}`;
  };

  function time(leaveData) {
    setLeaveDetail(leaveData);
    const startDateObj = new Date(leaveData?.startDate);
    const endDateObj = new Date(leaveData?.endDate);
    const oneDay = 24 * 60 * 60 * 1000;
    const startDateUTC = Date.UTC(
      startDateObj.getFullYear(),
      startDateObj.getMonth(),
      startDateObj.getDate()
    );
    const endDateUTC = Date.UTC(
      endDateObj.getFullYear(),
      endDateObj.getMonth(),
      endDateObj.getDate()
    );
    const diffDays = Math.floor((endDateUTC - startDateUTC) / oneDay) + 1;
    setDuration(diffDays);
  }

  const getStatusBackgroundColor = (status) => {
    switch (status) {
      case "Pending":
        return "var(--pending-color)";
      case "Rejected":
        return "var(--reject-color)";
      case "Approved":
        return "var(--approved-color)";
      case "Cancelled":
        return "var(--cancel-color)";
      default:
        return "";
    }
  };

  const validateRejectLeave = async () => {
    if (!comment) {
      setError("Reason is required.");
    } else {
      handleReject();
      const status = "Rejected";
      try {
        const postData = JSON.stringify({ comment, leaveId, status });
        const response = await upsertRejectLeave(postData)
        if (response.status === 201 || response.status === 200) {
          navigate("/leave-management");
          dispatch(setAllLeaveData(response.data.leave));
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message ?? "Leave Record Saved"));
          dispatch(setToastType("success"));
        }
      } catch (error) {
        setDisplayError(errorMessage ?? "")
      }
    }
  };

  const validateApproveLeave = async () => {
    if (!comment) {
      setError("Reason is required.");
    } else {
      handleApprove();
      const status = "Approved";
      try {
        const postData = JSON.stringify({ comment, leaveId, status });
        const response = await upsertApproveLeave(postData)
        if (response.status === 201 || response.status === 200) {
          navigate("/leave-management");
          dispatch(setAllLeaveData(response.data.leave));
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message ?? "Leave Record Saved"));
          dispatch(setToastType("success"));
        }
      } catch (error) {
        setDisplayError(errorMessage ?? "")
      }
    }
  };

  const validateCancelLeave = async () => {
    if (!comment) {
      setError("Reason is required.");
    } else {
      handleCancel();
      const status = "Cancelled";
      try {
        const postData = JSON.stringify({ comment, leaveId, status });
        const response = await upsertCancelLeave(postData);
        if (response.status === 201 || response.status === 200) {
          navigate("/leave-management");
          dispatch(setAllLeaveData(response.data.leave));
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message ?? "Leave Record Saved"));
          dispatch(setToastType("success"));
        }
      } catch (error) {
        setDisplayError(errorMessage ?? "")
      }
    }
  };

  const renderRejectLeaveDialog = () => {
    return (<Box sx={{ padding: "8px" }}>
      <DialogTitles
        sx={{ paddingX: "4px", paddingY: "0px" }}
        id="approval-dialog-title"
        isdarktheme={isDarkTheme ? "true" : "false"}
      >
        {"Reject Leave"}
      </DialogTitles>
      <DialogContent sx={{ paddingX: "4px", paddingY: "0px" }}>
        <CustomTextField
          label="Add Reason For Reject"
          multiline
          rows={3}
          variant="outlined"
          name="comment"
          onChange={handleCommentChange}
        />
        <Typography sx={{ color: "red" }}>{error}</Typography>
      </DialogContent>
      <DialogActions sx={{ paddingX: "4px", paddingY: "0px", marginTop: "10px" }}>
        <Button
          onClick={handleCloseRejectDialog}
          sx={{ color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82" }}
        >
          Cancel
        </Button>
        <Button onClick={() => validateRejectLeave()} variant="contained" color="error">
          Reject
        </Button>
      </DialogActions>
    </Box>)
  }

  const renderApproveLeaveDialog = () => {
    return (<Box sx={{ padding: "8px" }}>
      <DialogTitles
        sx={{ paddingX: "4px", paddingY: "0px" }}
        id="approval-dialog-title"
        isdarktheme={isDarkTheme ? "true" : "false"}
      >
        {"Approve Leave"}
      </DialogTitles>
      <DialogContent sx={{ paddingX: "4px", paddingY: "0px" }}>
        <CustomTextField
          label="Add Reason For Approved"
          multiline
          rows={3}
          variant="outlined"
          name="comment"
          onChange={handleCommentChange}
        />
        <Typography sx={{ color: "red" }}>{error}</Typography>
      </DialogContent>
      <DialogActions sx={{ paddingX: "4px", paddingY: "0px", marginTop: "10px" }}>
        <Button
          onClick={handleCloseApprovalDialog}
          sx={{ color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82" }}
        >
          Cancel
        </Button>
        <Button onClick={() => validateApproveLeave()} variant="contained" color="primary">
          Approve
        </Button>
      </DialogActions>
    </Box>)
  }

  const renderCancelLeaveDialog = () => {
    return (<Box sx={{ padding: "8px" }}>
      <DialogTitles
        sx={{ paddingX: "4px", paddingY: "0px" }}
        id="cancel-dialog-title"
        isdarktheme={isDarkTheme ? "true" : "false"}
      >
        {"Cancel Leave"}
      </DialogTitles>
      <DialogContent sx={{ paddingX: "4px", paddingY: "0px" }}>
        <CustomTextField
          label="Add Reason For Cancel"
          multiline
          rows={3}
          variant="outlined"
          name="comment"
          onChange={handleCommentChange}
        />
        <Typography sx={{ color: "red" }}>{error}</Typography>
      </DialogContent>
      <DialogActions sx={{ paddingX: "4px", paddingY: "0px", marginTop: "10px" }}>
        <Button
          onClick={handleCloseCancelDialog}
          sx={{ color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82" }}
        >
          Cancel
        </Button>
        <Button onClick={() => validateCancelLeave()} variant="contained" color="warning">
          Cancel Leave
        </Button>
      </DialogActions>
    </Box>)
  }

  const memoizedWarning = useMemo(() => {
    const warning = [];
    return warning;
  }, []);

  const memoizedleaveWarning = useMemo(() => {
    const leaveWarning = async () => {
      const startDateObj = new Date(leaveDetail.startDate);
      const endDateObj = new Date(leaveDetail.endDate);
      const currentDateObj = new Date(leaveDetail.submitDate);
      const oneDay = 24 * 60 * 60 * 1000;
      const startDateUTC = Date.UTC(
        startDateObj.getFullYear(),
        startDateObj.getMonth(),
        startDateObj.getDate()
      );
      const endDateUTC = Date.UTC(
        endDateObj.getFullYear(),
        endDateObj.getMonth(),
        endDateObj.getDate()
      );
      const currentDateUTC = Date.UTC(
        currentDateObj.getFullYear(),
        currentDateObj.getMonth(),
        currentDateObj.getDate()
      );
      const leaveDuration =
        Math.floor((endDateUTC - startDateUTC) / oneDay) + 1;
      const daysForLeave = Math.floor((startDateUTC - currentDateUTC) / oneDay);
      for (let i = 0; i < memoizedWarning?.length; i++) {
        memoizedWarning.pop();
      }
      if (leaveDetail.leaveType === "Sick Leave") {
        if (daysForLeave > 1) {
          memoizedWarning.push(
            "For sick leave advance leave request is not allowed."
          );
        }
      } else {
        if (leaveDuration === 1 && leaveDetail.leaveTime === "Half day") {
          if (daysForLeave < 4) {
            memoizedWarning.push(
              "For half day leave, 3 days prior leave request is needed."
            );
          }
        } else if (
          leaveDuration === 1 &&
          leaveDetail.leaveTime === "Early Leave(2hours max)"
        ) {
          if (daysForLeave < 1) {
            memoizedWarning.push(
              "For early leave, 1 day prior leave request is needed."
            );
          }
        } else if (
          (leaveDuration === 1 && leaveDetail.leaveTime === "Full day") ||
          leaveDuration === 2
        ) {
          if (daysForLeave < 8) {
            memoizedWarning.push(
              "For 1-2 day leave, one week prior leave request is needed."
            );
          }
        } else if (leaveDuration < 6 && leaveDuration > 2) {
          if (daysForLeave < 16) {
            memoizedWarning.push(
              "For 3-5 days leave, 15 days prior leave request is needed."
            );
          }
        } else if (leaveDuration > 6) {
          if (daysForLeave < 31) {
            memoizedWarning.push(
              "For one week leave, one Month prior leave request is needed."
            );
          }
        }
      }
      setWarningArray(memoizedWarning);
    };
    return leaveWarning;
  }, [
    leaveDetail?.endDate,
    leaveDetail?.leaveTime,
    leaveDetail?.leaveType,
    leaveDetail?.startDate,
    leaveDetail?.submitDate,
    memoizedWarning,
  ]);

  const memoizedcheckStatus = useMemo(() => {
    const checkStatus = () => {
      if (leaveDetail.status === "Approved") {
        setIsApproved(true);
      }
      if (leaveDetail.status === "Rejected") {
        setIsRejected(true);
      }
      if (leaveDetail.status === "Pending") {
        setIsCancelled(false);
        setIsRejected(false);
        setIsApproved(false);
      }
      if (leaveDetail.status === "Cancelled") {
        setIsCancelled(true);
      }
    };
    return checkStatus;
  }, [leaveDetail.status]);

  useEffect(() => {
    if (updatedLeaveType !== "") {
      setLeaveDetail((prevLeaveDetail) => ({
        ...prevLeaveDetail,
        leaveType: {
          ...prevLeaveDetail.leaveType,
          leaveType: updatedLeaveType, // Update the 'leaveType' property within 'leaveType'
        },
      }));
      dispatch(setUpdatedLeaveType(""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedLeaveType]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await getEmpDetails(employeeId);
        if (response.status === 200 || response.status === 201) {
          setIsLoading(false);
          setEmployeeData(response.data.user);
        }
        else {
          setIsLoading(false);
          setEmployeeData();
        }
      } catch (error) {
        setIsLoading(false);
      }
    };
    memoizedleaveWarning();
    fetchData();
    memoizedcheckStatus();
  }, [
    employeeId,
    navigate,
    token,
    memoizedleaveWarning,
    memoizedcheckStatus,
    dispatch,
  ]);

  return (
    <>
      {backFromUpdate ? (
        <Box>
          {isLoading ? (
            <ViewLeaveLoadingBox>
              <Loader />
            </ViewLeaveLoadingBox>
          ) : (
            <MainBox>
              <InnerBoxOne>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={viewProfileTitle} component="div">
                    <IconButton onClick={onBackClick}>
                      <ArrowBackIcon color="primary" />
                    </IconButton>
                    Employee Leave Detail{" "}
                  </Typography>
                  <Typography
                    component="div"
                    style={{
                      backgroundColor: getStatusBackgroundColor(
                        leaveDetail.status
                      ),
                      color: "#fff",
                      padding: "10px",
                      borderRadius: "5px",
                      marginLeft: "10px",
                    }}
                  >
                    {leaveDetail.status}
                  </Typography>
                </Box>
                {isCancelled || isRejected || (isApproved && !isCEO) ? (
                  <>{/* Content when isCancelled is true */}</>
                ) : (
                  (isEditDeleteVisible || isCEO) && (
                    <Box>
                      <Tooltip
                        title="Edit Leave"
                        arrow
                        disableInteractive
                        TransitionComponent={Zoom}
                      >
                        <IconButton
                          sx={{ color: "var(--third-color)" }}
                          onClick={openUpdateLeave}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                      {/* Additional content related to isEditDeleteVisible */}
                    </Box>
                  )
                )}
              </InnerBoxOne>
              <Box>
                <Box
                  sx={{
                    padding: "0px 0px 0px 20px",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4}>
                      <CustomTypography
                        viewprofilesubtitle={viewProfileSubtitle}
                      >
                        Employee Name
                      </CustomTypography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Typography sx={viewLeaveDetail}>
                        {capitalizeFirstLetter(leaveDetail.employeeName)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={1}>
                    <Grid item xs={12} sm={4}>
                      <CustomTypography
                        viewprofilesubtitle={viewProfileSubtitle}
                      >
                        Contact No.
                      </CustomTypography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Typography sx={viewLeaveDetail}>
                        {employeeData?.Contact_Number}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={1}>
                    <Grid item xs={12} sm={4}>
                      <CustomTypography
                        viewprofilesubtitle={viewProfileSubtitle}
                      >
                        Email
                      </CustomTypography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Typography sx={viewLeaveDetail}>
                        {employeeData?.Email}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={1}>
                    <Grid item xs={12} sm={4}>
                      <CustomTypography
                        viewprofilesubtitle={viewProfileSubtitle}
                      >
                        Leave Application Date
                      </CustomTypography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Typography sx={viewLeaveDetail}>
                        {formatDate(leaveDetail.submitDate)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={1}>
                    <Grid item xs={12} sm={4}>
                      <CustomTypography
                        viewprofilesubtitle={viewProfileSubtitle}
                      >
                        Leave Type
                      </CustomTypography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Typography sx={viewLeaveDetail}>
                        {leaveDetail?.leaveType?.leaveType}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4}>
                      <CustomTypography
                        viewprofilesubtitle={viewProfileSubtitle}
                      >
                        Reason For Leave
                      </CustomTypography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Typography sx={viewLeaveDetail}>
                        {leaveDetail.reason}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={1}>
                    <Grid item xs={12} sm={4}>
                      <CustomTypography
                        viewprofilesubtitle={viewProfileSubtitle}
                      >
                        Leave start and End Date
                      </CustomTypography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Typography sx={viewLeaveDetail}>
                        {formatDate(leaveDetail.startDate)} To{" "}
                        {formatDate(leaveDetail?.endDate)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={1}>
                    <Grid item xs={12} sm={4}>
                      <CustomTypography
                        viewprofilesubtitle={viewProfileSubtitle}
                      >
                        Total Days of Leave
                      </CustomTypography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Typography sx={viewLeaveDetail}>{duration}</Typography>
                    </Grid>
                  </Grid>
                  {EmployeeLogIn ? (
                    <>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={4}>
                          <CustomTypography
                            viewprofilesubtitle={viewProfileSubtitle}
                          >
                            Reason
                          </CustomTypography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Typography sx={viewLeaveDetail}>
                            {leaveDetail.response}
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  ) : null}

                  {warningArray?.length !== 0 ? (
                    <>
                      <WarningMainBox fullwidth="true">
                        <WarningTypography>
                          <WarningAmber
                            sx={{
                              mr: 1,
                            }}
                          />
                          Warning
                        </WarningTypography>
                        <WarningDetailBox>
                          {warningArray?.map((warning, index) => (
                            <Box key={index}>
                              {index + 1}. {warning}
                            </Box>
                          ))}
                        </WarningDetailBox>
                      </WarningMainBox>
                    </>
                  ) : null}
                </Box>
              </Box>
              <ErrorTypography>{displayError}</ErrorTypography>
              {adminLogin ? (
                <>
                  {isCancelled || isRejected ? null : (
                    <>
                      {isApproved ? (
                        <>
                          <FooterBox>
                            {" "}
                            <CancelButton onClick={handleOpenCancelDialog}>
                              Cancel
                            </CancelButton>
                            {/* <CancelDialog
                              open={openCancelDialog}
                              leaveId={leaveId}
                              onClose={handleCloseCancelDialog}
                              onApprove={handleCancel}
                              setDisplayError={setDisplayError}
                            /> */}
                            <DialogComponent
                              sx={{
                                ".MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper":
                                {
                                  backgroundColor: isDarkTheme
                                    ? "#0b1727 !important"
                                    : "#ffffff !important",
                                },
                              }}
                              arialabelledby="cancel-dialog-title"
                              ariadescribedby="cancel-dialog-description"
                              open={openCancelDialog}
                              onClose={handleCloseCancelDialog}
                              renderChildern={renderCancelLeaveDialog}
                            />
                          </FooterBox>
                        </>
                      ) : (
                        <>
                          <Box>
                            <FooterBox>
                              {" "}
                              <CancelButton onClick={handleOpenCancelDialog}>
                                Cancel
                              </CancelButton>
                              {/* <CancelDialog
                                open={openCancelDialog}
                                leaveId={leaveId}
                                onClose={handleCloseCancelDialog}
                                onApprove={handleCancel}
                                setDisplayError={setDisplayError}
                              /> */}
                              <DialogComponent
                                sx={{
                                  ".MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper":
                                  {
                                    backgroundColor: isDarkTheme
                                      ? "#0b1727 !important"
                                      : "#ffffff !important",
                                  },
                                }}
                                arialabelledby="cancel-dialog-title"
                                ariadescribedby="cancel-dialog-description"
                                open={openCancelDialog}
                                onClose={handleCloseCancelDialog}
                                renderChildern={renderCancelLeaveDialog}
                              />
                              <Tooltip
                                title="Approve Leave"
                                arrow
                                disableInteractive
                                TransitionComponent={Zoom}
                              >
                                <CustomButton
                                  variant="contained"
                                  color="primary"
                                  onClick={handleOpenApprovalDialog}
                                >
                                  Approve
                                </CustomButton>
                              </Tooltip>
                              {/* <ApproveDialog
                                open={openApprovalDialog}
                                leaveId={leaveId}
                                onClose={handleCloseApprovalDialog}
                                onApprove={handleApprove}
                                setDisplayError={setDisplayError}
                              /> */}
                              <DialogComponent
                                sx={{
                                  ".MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper":
                                  {
                                    backgroundColor: isDarkTheme
                                      ? "#0b1727  !important"
                                      : "#ffffff !important",
                                  },
                                }}
                                open={openApprovalDialog}
                                onClose={handleCloseApprovalDialog}
                                arialabelledby="approval-dialog-title"
                                ariadescribedby="approval-dialog-description"
                                renderChildern={renderApproveLeaveDialog}
                              />
                              <Tooltip
                                title="Reject Leave"
                                arrow
                                disableInteractive
                                TransitionComponent={Zoom}
                              >
                                <CustomButton
                                  variant="contained"
                                  color="error"
                                  onClick={handleOpenRejectDialog}
                                >
                                  Reject
                                </CustomButton>
                              </Tooltip>
                              {/* <RejectDialog
                                open={openRejectDialog}
                                onClose={handleCloseRejectDialog}
                                onReject={handleReject}
                                leaveId={leaveId}
                                setDisplayError={setDisplayError}
                              /> */}
                              <DialogComponent
                                sx={{
                                  ".MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper":
                                  {
                                    backgroundColor: isDarkTheme
                                      ? "#0b1727 !important"
                                      : "#ffffff !important",
                                  },
                                }}
                                arialabelledby="approval-dialog-title"
                                ariadescribedby="approval-dialog-description"
                                open={openRejectDialog}
                                onClose={handleCloseRejectDialog}
                                renderChildern={renderRejectLeaveDialog}
                              />
                            </FooterBox>
                          </Box>
                        </>
                      )}
                    </>
                  )}
                </>
              ) : null}
            </MainBox>
          )}
        </Box>
      ) : (
        <UpdateLeave backToView={backToView} />
      )}
    </>
  );
};
export default ViewLeaveDetail;
