import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box, Button, Tooltip, Typography, Zoom,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridToolbar } from "@mui/x-data-grid";

import Loader from "../Loader/Loader";
import { numberArray } from "../Constant/constant";
import { setToast, setToastMsg, setToastType } from "../../Slices/toastSlice";
import { setGetNotificationCount } from "../../Slices/selectedActive";
import {
  deletenotification,
  getNotificationByEmpId,
  upsertNotificationsMarkAsRead,
} from "../../Services/DashboardServices";
import {
  MainBox,
  MarkAsBox,
  InnerBoxOne,
  LoadingBox,
  TitleTypography,
  DataGrids,
} from "../../Styles/Notification/NotificationStyle";

function NotificationDetail() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:600px)");
  const user = useSelector((state) => state.selectedActiveView.role);
  const notificationCount = useSelector(
    (state) => state.selectedActiveView.getNotificationCount
  );
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const currentEmployeeId = useSelector(
    (state) => state.addEmployee.current?.empId
  );
  const currentAdminId = useSelector(
    (state) => state.adminData.adminData?.empId
  );
  const employeeDisplay = user === "employee";
  const adminLogin = user === "admin";
  const empId = employeeDisplay ? currentEmployeeId : currentAdminId;

  const [notifications, setNotifications] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [dataGridHeight, setDataGridHeight] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [getdata, setGetData] = useState([]);

  const rows = notifications?.map((item, index) => {
    return {
      id: index + 1,
      subject: item?.type,
      description: item?.message,
      isRead: item?.user?.find((item) => item?.empId === empId)?.isRead,
      user: item?.user,
      notifId: item?._id,
    };
  });

  const columns = [
    {
      field: "subject",
      headerName: "Subject",
      flex: 1,
      renderCell: (params) => {
        const { isRead } = params.row;
        return (
          <Typography sx={{ fontWeight: isRead ? "normal" : "bold" }}>
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      flex: 4,
      renderCell: (params) => {
        const { isRead } = params.row;
        return (
          <Typography sx={{ fontWeight: isRead ? "normal" : "bold" }}>
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.7,
      renderCell: (params) => (
        <Box sx={{ display: "flex", gap: "2px" }}>
          <Tooltip
            title="Delete"
            placement="bottom"
            arrow
            TransitionComponent={Zoom}
          >
            <IconButton
              sx={{
                color: isDarkTheme ? "#d94a38" : "#c62828",
              }}
              variant="contained"
              onClick={() => deleteNotification(params?.row)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const handleRowSelectionChange = (newSelectionModel) => {
    const selectedIds = newSelectionModel;
    const selectedEmployeeIds = rows
      .filter((row) => selectedIds?.includes(row?.id))
      .map((row) => row);
    const filterData = selectedEmployeeIds?.map((item) => {
      return {
        id: item?.notifId,
        empId: item?.user?.find((item) => item)?.empId,
        isRead: item?.user?.find((item) => item)?.isRead,
      };
    });
    setSelectedRows(newSelectionModel);
    setGetData(filterData);
  };

  const deleteNotification = async (row) => {
    // const user = employeeDisplay ? "user" : adminLogin ? "admin" : "";
    const user = employeeDisplay ? 'user' : adminLogin ? 'admin' : '';
    if (!user) return;

    const confirmed = window.confirm('Are you sure you want to delete this notification?');
    if (!confirmed) return;

    if (user) {
      try {
        const postData = JSON.stringify({
          id: row?.notifId,
          empId: row?.user?.find?.((item) => item)?.empId,
        });
        const response = await deletenotification(user, postData);
        if (response.status === 200 || response.status === 201) {
          const filterData = notifications?.filter(
            (item) => item?._id !== row?.notifId
          );
          setNotifications(filterData);
          setTotalCount(totalCount - 1);
          const isread = row?.user?.find?.((item) => item)?.isRead;
          if (isread === false) {
            dispatch(setGetNotificationCount(notificationCount - 1));
          }
          dispatch(setToast(true));
          dispatch(
            setToastMsg(
              response.data.message ?? "Notification deleted successfully."
            )
          );
          dispatch(setToastType("success"));
        }
      } catch (error) {
        // setDisplayError(errorMessage ?? "")
      }
    }
  };

  const markAsReadUnRead = async (value) => {
    const user = employeeDisplay ? "user" : adminLogin ? "admin" : "";
    if (user) {
      try {
        let notificationsToUpdate;
        if (value === "markasread") {
          notificationsToUpdate = getdata
            ?.filter((notification) => notification.isRead === false)
            ?.map((notification) => notification);
        } else if (value === "markasunread") {
          notificationsToUpdate = getdata
            ?.filter((notification) => notification.isRead === true)
            ?.map((notification) => notification);
        } else {
          console.error("Invalid value provided:", value);
          return;
        }
        if (notificationsToUpdate.length === 0) {
          console.log("No notifications to update.");
          return;
        }
        const postData = JSON.stringify({
          notifications: notificationsToUpdate,
        });
        const response = await upsertNotificationsMarkAsRead(user, postData);
        if (response.status === 200 || response.status === 201) {
          dispatch(setToast(true));
          dispatch(
            setToastMsg(
              (value === "markasread"
                ? "Notification marked as read."
                : "Notification marked as unread.") ?? "Notification updated."
            )
          );
          dispatch(setToastType("success"));
          dispatch(setGetNotificationCount(response.data.notificationCount));
          //   Update local notifications state
          setNotifications((prevNotifications) =>
            prevNotifications?.map((notification) => {
              const updatedNotification = notificationsToUpdate?.find(
                (n) => n?.id === notification._id
              );
              if (updatedNotification) {
                const updatedUser = notification?.user?.map((user) =>
                  user?.empId === updatedNotification?.empId
                    ? {
                      ...user,
                      isRead: updatedNotification?.isRead ? false : true,
                    }
                    : user
                );
                return { ...notification, user: updatedUser };
              }
              return notification;
            })
          );
          // Clear selected rows and data
          setSelectedRows([]);
          setGetData([]);
        }
      } catch (error) {
        //   setDisplayMessageError(errorMessage ?? "")
      }
    }
  };

  const fetchNotification = async () => {
    const user = employeeDisplay ? "user" : adminLogin ? "admin" : "";
    const empId = employeeDisplay ? currentEmployeeId : currentAdminId;
    if (user && empId) {
      setIsLoading(true);
      try {
        const response = await getNotificationByEmpId(
          user,
          empId,
          pageNumber,
          pageSize
        );
        if (response.status === 200 || response.status === 201) {
          setIsLoading(false);
          setNotifications((prevNotifications) => [
            ...response.data.notifications,
          ]);
          setTotalCount(response.data.totalCount);
          setPageSize(response?.data?.pageSize || pageSize);
          setPageNumber(response?.data?.pageNumber || pageNumber);
        } else {
          setIsLoading(false);
          setNotifications([]);
          setTotalCount(0);
          setPageSize(10);
          setPageNumber(1);
        }
      } catch (error) {
        setIsLoading(false);
        console.log("error:", error);
      }
    }
  };

  useEffect(() => {
    fetchNotification();
    // eslint-disable-next-line
  }, [pageNumber, pageSize]);

  return (
    <Box>
      <MainBox>
        {isMobile ? (
          <InnerBoxOne
            sx={{
              "@media (max-width: 600px)": {
                flexDirection: "column",
                alignItems: "flex-start",
              },
            }}
          >
            <Box
              sx={{
                padding: "5px 0px 0px 18px",
                display: "flex",
                "@media (max-width: 600px)": {
                  padding: "0px 0px 0px 0px",
                },
              }}
            >
              <TitleTypography variant="h6">Notifications</TitleTypography>
            </Box>
            {selectedRows?.length > 0 && (
              <MarkAsBox>
                <Tooltip
                  title="Mark As Read"
                  arrow
                  disableInteractive
                  TransitionComponent={Zoom}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      marginX: "15px",
                      "@media (max-width: 600px)": {
                        margin: "5px",
                        marginRight: "15px",
                      },
                    }}
                    onClick={() => markAsReadUnRead("markasread")}
                  >
                    Mark As Read
                  </Button>
                </Tooltip>
                <Tooltip
                  title="Mark As UnRead"
                  arrow
                  disableInteractive
                  TransitionComponent={Zoom}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      backgroundColor: "var(--secondary-color)",
                      "&:hover": {
                        color: "none",
                        backgroundColor: "var(--secondary-color-H)",
                      },
                      color: "#ffffff",
                    }}
                    onClick={() => markAsReadUnRead("markasunread")}
                  >
                    Mark As UnRead
                  </Button>
                </Tooltip>
              </MarkAsBox>
            )}
          </InnerBoxOne>
        ) : (
          <InnerBoxOne>
            <Box
              sx={{
                padding: "5px 0px 0px 18px",
                display: "flex",
                "@media (max-width: 600px)": {
                  padding: "0px 0px 0px 0px",
                },
              }}
            >
              <TitleTypography variant="h6">Notifications</TitleTypography>
            </Box>
            {selectedRows?.length > 0 && (
              <MarkAsBox>
                <Tooltip
                  title="Mark As Read"
                  arrow
                  disableInteractive
                  TransitionComponent={Zoom}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ marginX: "15px" }}
                    onClick={() => markAsReadUnRead("markasread")}
                  >
                    Mark As Read
                  </Button>
                </Tooltip>
                <Tooltip
                  title="Mark As UnRead"
                  arrow
                  disableInteractive
                  TransitionComponent={Zoom}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      backgroundColor: "var(--secondary-color)",
                      "&:hover": {
                        color: "none",
                        backgroundColor: "var(--secondary-color-H)",
                      },
                      color: "#ffffff",
                    }}
                    onClick={() => markAsReadUnRead("markasunread")}
                  >
                    Mark As UnRead
                  </Button>
                </Tooltip>
              </MarkAsBox>
            )}
          </InnerBoxOne>
        )}
        {isLoading ? (
          <LoadingBox>
            <Loader />
          </LoadingBox>
        ) : (
          <Box
            sx={{ width: "100%", maxHeight: "fit-content", overflowX: "auto" }}
          >
            <Box
              sx={{
                "@media (max-width: 600px)": {
                  width: "290%",
                },
              }}
            >
              <DataGrids
                disableColumnMenu
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                aria-selected="false"
                rows={rows ?? []}
                checkboxSelection
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  handleRowSelectionChange(newRowSelectionModel);
                }}
                disableRowSelectionOnClick
                // selectionModel={selectedRows}
                rowSelectionModel={selectedRows}
                rowCount={totalCount}
                paginationMode="server"
                getRowId={(row) => row.id}
                columns={columns ?? []}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      startDate: false,
                    },
                  },
                  pagination: {
                    paginationModel: {
                      pageSize:
                        parseInt(
                          window.localStorage.getItem("pageSize"),
                          pageSize
                        ) || pageSize,
                      page: pageNumber - 1,
                    },
                    // rowCount: totalCount
                  },
                }}
                onRowCountChange={(params) => {
                  if (params === 0) {
                    setDataGridHeight(true);
                  } else {
                    setDataGridHeight(false);
                  }
                }}
                onPaginationModelChange={(pageSize) => {
                  setPageSize(pageSize?.pageSize);
                  setPageNumber(pageSize?.page + 1);
                  window.localStorage.setItem(
                    "paginationNo",
                    pageSize.pageSize
                  );
                }}
                onPageSizeChange={(pageSize) => {
                  window.localStorage.setItem("paginationNo", pageSize);
                }}
                pageSizeOptions={numberArray}
                components={{
                  Toolbar: GridToolbar,
                }}
                dataGridHeight={dataGridHeight}
                sx={{
                  "& .MuiDataGrid-cell": {
                    backgroundColor: (params) =>
                      params.rowIndex % 2 === 0
                        ? "var(--highlight-color)"
                        : "var(--background-table-sidebar-card-color)",
                  },
                }}
              />
            </Box>
          </Box>
        )}
      </MainBox>
    </Box>
  );
}

export default NotificationDetail;
