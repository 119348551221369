import { Box, Button, IconButton, TextField, Typography, styled } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"

export const MainBox = styled(Box)(() => ({
    borderBottom: "1px solid var(--table-border-color)",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    '@media (max-width: 600px)': {
        flexDirection: "column",
        alignItems: "flex-start",
    },
}))

export const TitleTypography = styled(Typography)(() => ({
    color: "var(--primary-color)",
    fontWeight: "bold",
    marginLeft: "15px",
    '@media (max-width: 600px)': {
        marginLeft: "4px",
    },
}))

export const InnerBoxOne = styled(Box)(() => ({
    width: "100%",
    maxHeight: "fit-content"
}))

export const CloseIconButton = styled(IconButton)(({ isdarktheme }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "10px",
    right: "-5px",
    fontWeight: "bold",
    textTransform: "capitalize",
    marginRight: "15px",
    fontSize: "2px",
    "&:hover": {
        background: "var(--highlight-color)",
    },
}))

export const ManageRoleDataGrid = styled(DataGrid)(({ dataGridHeight }) => ({
    height: dataGridHeight ? "230px" : "fit-content",
    ".MuiDataGrid-filler": {
        height: "0px !important",
        display: "none",
    },
    color: "var(--primary-text-color)",
    textTransform: "capitalize",
    ".MuiDataGrid-columnHeaderTitle": {
        textTransform: "none !important",
    },
    backgroundColor: "var(--subAccordian-background-color)",
    ".MuiDataGrid-cell ": {
        cursor: "pointer",
        alignContent: "center",
    },
    ".MuiDataGrid-cell:focus": {
        outline: "none",
    },
    ".MuiDataGrid-columnHeader:focus": {
        outline: "none",
    },
    ".MuiDataGrid-columnHeader": {
        height: "48px !important",
    },
    ".MuiButtonBase-root:focus": {
        outline: "none",
    },
    ".MuiSvgIcon-fontSizeSmall": {
        fill: "var(--primary-text-color) !important",
    },
    ".MuiInputBase-input.MuiInput-input.MuiInputBase-inputTypeSearch.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd":
    {
        color: "var(--primary-text-color) !important",
    },
    ".MuiDataGrid-overlay": {
        color: "var(--primary-text-color) !important",
        backgroundColor:
            "var(--subAccordian-background-color)",
    },
    ".MuiInput-underline:before": {
        borderBottom:
            "1px solid var(--primary-text-color) !important",
    },
    // ".MuiSelect-icon": {
    //   fill: "var(--secondary-text-color)",
    // },
    // ".MuiTablePagination-root": {
    //   color: "var(--secondary-text-color)",
    // },
    // ".MuiDataGrid-root": {
    //   color: "var(--secondary-text-color)",
    // },
    // ".MuiDataGrid-cellContent": {
    //   color: "var(--secondary-text-color)",
    //   fontSize: "14px",
    // },
    // ".MuiDataGrid-columnHeader": {
    //   color: "var(--secondary-text-color)",
    //   fontSize: "18px",
    //   backgroundColor: "var(--primary-highlight-color)",
    //   width: "100vh",
    // },
    // ".MuiDataGrid-footerContainer": {
    //   color: "var(--secondary-text-color)",
    // },
    // ".MuiDataGrid-menuIconButton": {
    //   color: "var(--secondary-text-color)",
    // },
    // ".MuiDataGrid-sortIcon": {
    //   color: "var(--secondary-text-color)",
    //   visibility: true,
    // },
    // "MuiDataGrid-virtualScroller": { color: "red" },
    ".MuiDataGrid-actionsCell": { type: "actions" },
    // ".MuiDataGrid-columnHeader:focus": {
    //   outlineColor: "rgb(25, 118, 210)",
    //   outlineStyle: "solid",
    //   outlineWidth: "0px",
    // },
    // color: "var(--secondary-text-color)",
    // // overflow: overlay
    // ".MuiDataGrid-cell:focus": {
    //   outlineColor: "rgb(25, 118, 210)",
    //   outlineStyle: "solid",
    //   outlineWidth: "0px",
    // },
}))

export const InnerBoxTwo = styled(Box)(() => ({
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "550px",
    height: "75px",
}))

export const InnerBoxThree = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}))

export const InnerBoxFour = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#f4b73e25",
    borderRadius: "1000px",
    padding: "8px",
    marginRight: "25px",
}))

export const InnerBoxFive = styled(Box)(({ isdarktheme }) => ({
    display: "flex",
    justifyContent: "left",
    marginTop: "-15px",
    paddingLeft: "35px",
    marginRight: "25px",
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
}))

export const InnerBoxSix = styled(Box)(({ isdarktheme }) => ({
    display: "flex",
    justifyContent: "left",
    marginTop: "20px",
    paddingLeft: "35px",
    marginRight: "25px",
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
}))

export const ErrorTypography = styled(Typography)(() => ({
    color: "red",
    paddingLeft: "38px",
    marginTop: "0px",
}))

export const LinkBox = styled(Box)(({ isdarktheme }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    paddingLeft: "36px",
    marginTop: "5px",
}))

export const GroupNameBox = styled(Box)(({ isdarktheme }) => ({
    display: "flex",
    justifyContent: "left",
    marginTop: "15px",
    paddingLeft: "35px",
    marginRight: "25px",
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
}))

export const GroupNameTextField = styled(TextField)(() => ({
    width: { sm: 50, md: 350 },
    "& .MuiInputBase-input": {
        height: "0px",
    },
}))

export const LevelNameBox = styled(Box)(({ isdarktheme }) => ({
    display: "flex",
    justifyContent: "left",
    marginTop: "20px",
    paddingLeft: "35px",
    marginRight: "25px",
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
}))

export const CancelButton = styled(Button)(({ isdarktheme }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    textTransform: "capitalize",
    fontWeight: "bold",
    marginRight: "10px",
    borderRadius: "5px",
}))

export const UpsertRoleButton = styled(Button)(() => ({
    borderRadius: "5px",
    textTransform: "capitalize",
}))

export const InnerMainBox = styled(Box)(() => ({
    paddingBottom: "5px",
    '@media (max-width: 600px)': {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginLeft: "4px",
        marginTop: "10px",
    },
}))

export const AddRoleButton = styled(Button)(() => ({
    marginRight: "15px",
    '@media (max-width: 600px)': {
        marginLeft: "0px",
        display:"flex",
        justifyItems:"flex-end"
    },
    // background: "var(--primary-color)",
}))