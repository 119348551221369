import React, { useState, useEffect } from "react";
import moment from "moment";
import { Box } from "@mui/material";

function DigitalClock() {
  const [currentTime, setCurrentTime] = useState(moment().format("hh:mm:ss A"));
  const [currentDate, setCurrentDate] = useState(moment().format("ddd, Do MMMM YYYY"));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format("hh:mm:ss A"));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const updateDate = () => {
      const newDate = moment().format("ddd, Do MMMM YYYY");
      if (newDate !== currentDate) {
        setCurrentDate(newDate);
      }
    };
    updateDate();
    const dateInterval = setInterval(updateDate, 60000);
    return () => clearInterval(dateInterval);
  }, [currentDate]);

  return (
    <Box
      sx={{
        width: "142px",
        padding: "7px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Jost, sans-serif",
        color: "var(--secondary-text-color)",
        fontWeight: "500",
        userSelect: "none",
      }}
    >
      <Box sx={{ fontSize: "1.10em" }}>
        {currentTime}
      </Box>
      <Box sx={{ fontSize: "0.72em" }}>
        {currentDate}
      </Box>
    </Box>
  );
}

export default DigitalClock;
