import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Box,
	Button,
	InputAdornment,
	Typography,
} from "@mui/material";

import {
	setToast,
	setToastMsg,
	setToastType,
} from "../../Slices/toastSlice";
import SelectEmployeeForLeaves from "./SelectEmployeeForLeaves";
import Loader from "../Loader/Loader";
import DialogComponent from '../ReusableComponents/DialogBoxComponents/DialogComponent'
import { errorMessage } from "../../Services/axiosInstance";
import { editMonthlyRecord, upsertAssignLeave, upsertMonthlyRecord } from "../../Services/LeaveManageServices";
import { DialogTitles, OutlinedInputs, CancelButton, DialogsActions, PerInputAdornment, ErrorTypography, CustomTextField, LabelTypography, DialogContents, InnerBoxOne, LoadingBox, MainBox, TitleBox, DetailsBox } from "../../Styles/CompanyAndLeavePolicy/LeaveAssignCardStyle"

function LeaveAssignCard(props) {
	const {
		selectedDataDailog,
		open,
		setOpenDailogBox,
		change,
		setChange,
		employeeList,
		dates,
		selectEmployeeCard,
		selectDates,
		updateMonthlyRecord,
	} = props;
	const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
	const dispatch = useDispatch();
	const themeChange = useSelector((state) => state.theme.isDarkTheme);

	const [assignData, setAssignData] = useState({
		startDate: "",
		endDate: "",
		percentage: "",
	});
	const [error, setError] = useState({});
	const [limitDate, setLimitDate] = useState("");
	// const [selectEmployee, setSelectEmployee] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [employeeSelectedForLeave, setEmployeeSelectedForLeave] = useState([]);
	const [employeeSelectedForLeaveEmpId, setEmployeeSelectedForLeaveEmpId] = useState([]);
	const [displayError, setDisplayError] = useState("");

	const formattedDate = selectedDataDailog?.nextDate
		? new Date(selectedDataDailog?.nextDate).toISOString().split("T")[0]
		: "";

	const nextDate = selectedDataDailog?.nextDate
		? new Date(selectedDataDailog?.nextDate)
		: new Date();
	const nextYear = nextDate.getFullYear() + 1; // Increment year by 1
	const formattedEndDate = new Date(nextYear, 2, 31)
		.toISOString()
		.split("T")[0];

	const onClose = () => {
		setOpenDailogBox(false);
	}

	const handleInputChange = (event) => {
		setDisplayError("");
		const { name, value } = event.target;
		// if (name !== "percentage") {
		setAssignData((prevData) => ({
			...prevData,
			[name]: value,
		}));
		setError((prevData) => ({
			...prevData,
			[name]: "",
		}));
		// } else {
		//   if (/^(100|\d{1,2})?$/.test(value)) {
		//     setError((prevData) => ({
		//       ...prevData,
		//       [name]: "",
		//     }));
		//     setAssignData((prevData) => ({
		//       ...prevData,
		//       [name]: value,
		//     }));
		//   }
		// }
	};

	const assignLeaves = async () => {
		if (
			employeeSelectedForLeave.length ===
			employeeSelectedForLeaveEmpId.length &&
			employeeSelectedForLeaveEmpId.length > 0
		) {
			setIsLoading(true);
			setDisplayError("");
			try {
				const assignData = {
					endDate: dates.endDate.split("T")[0],
					percentage: dates.percentage,
					startDate: dates.startDate.split("T")[0],
					id: dates.id,
				};
				// const response = await axios.post(
				// 	`${CLOUD_FUNCTIONS_ORIGIN}/admin/assignLeave`,
				// 	JSON.stringify({
				// 		assignData,
				// 		employeeSelectedForLeave,
				// 		employeeSelectedForLeaveEmpId,
				// 	}),
				// 	postHeader
				// );
				const postData = JSON.stringify({ assignData, employeeSelectedForLeave, employeeSelectedForLeaveEmpId, })
				const response = await upsertAssignLeave(postData)
				if (response.status === 200) {
					if (!response.data.success) {
						setIsLoading(false);
						setDisplayError(response.data.message);
					} else {
						setIsLoading(false);
						setOpenDailogBox(false);
						if (change === true) {
							setChange(false);
						} else {
							setChange(true);
						}
						dispatch(setToast(true));
						dispatch(setToastMsg(response.data.message ?? "Leaves Assign"));
						dispatch(setToastType("success"));
					}
				}
			} catch (error) {
				setIsLoading(false)
				setDisplayError(errorMessage ?? "")
			}
		} else {
			setDisplayError("Employee selection is not proper.");
		}
	};

	const validationAndsave = async () => {
		setIsLoading(true);
		setDisplayError("");
		try {
			// const response = await axios.post(
			// 	`${CLOUD_FUNCTIONS_ORIGIN}/admin/addMonthlyRecord`,
			// 	JSON.stringify({ assignData }),
			// 	postHeader
			// );
			const postData = JSON.stringify({ assignData });
			const response = await upsertMonthlyRecord(postData)
			if (response.status === 200) {
				setIsLoading(false);
				setOpenDailogBox(false);
				setError({});
				setAssignData({
					startDate: "",
					endDate: "",
					percentage: "",
				});
				dispatch(setToast(true));
				dispatch(setToastMsg("Monthly Record Added!"));
				dispatch(setToastType("success"));
				if (change === true) {
					setChange(false);
				} else {
					setChange(true);
				}
			}
		} catch (error) {
			setIsLoading(false);
			setDisplayError(errorMessage ?? "")
		}
	};

	const validationAndUpdate = async () => {
		setIsLoading(true);
		setDisplayError("");
		try {
			const id = dates.id;
			// const response = await axios.put(
			// 	`${CLOUD_FUNCTIONS_ORIGIN}/admin/updateMonthlyRecord`,
			// 	JSON.stringify({ assignData, id }),
			// 	postHeader
			// );
			const postData = JSON.stringify({ assignData, id });
			const response = await editMonthlyRecord(postData);
			if (response.status === 200) {
				setOpenDailogBox(false);
				setError({});
				setIsLoading(false);
				setAssignData({
					startDate: "",
					endDate: "",
					percentage: "",
				});
				dispatch(setToast(true));
				dispatch(setToastMsg(response.data.message ?? "Records updated sucessfully"));
				dispatch(setToastType("success"));
				if (change === true) {
					setChange(false);
				} else {
					setChange(true);
				}

			} else if (response.status === 202) {
				setIsLoading(false);
				setDisplayError(response.data.message);
			}
		} catch (error) {
			setIsLoading(false);
			setDisplayError(errorMessage ?? "")
		}
	};

	const validation = async () => {
		const newErrors = {};
		if (!assignData.startDate) {
			newErrors.startDate = "Please select start date";
		}
		if (!assignData.endDate) {
			newErrors.endDate = "Please select end date";
		}

		if (assignData.startDate && assignData.endDate) {
			if (assignData.endDate < assignData.startDate) {
				newErrors.endDate = "End date can't be smaller then start date";
			} else if (assignData.endDate === assignData.startDate) {
				newErrors.endDate = "End date can't be same as start date";
			}
		}

		if (!assignData.percentage) {
			newErrors.percentage = "Please enter percentage";
		} else if (!/^\d+(\.\d+)?$/.test(assignData.percentage)) {
			newErrors.percentage = "Please enter valid percentage";
		} else if (!updateMonthlyRecord) {
			if (selectedDataDailog?.percentageLeft < assignData.percentage) {
				newErrors.percentage = "Insufficient percentage";
			}
		} else if (updateMonthlyRecord) {
			if (
				selectedDataDailog?.percentageLeft + dates.percentage <
				assignData.percentage
			) {
				newErrors.percentage = "Insufficient percentage";
			}
		}
		setError(newErrors);
		const noErrors = Object.keys(newErrors).length === 0;

		if (noErrors) {
			updateMonthlyRecord ? validationAndUpdate() : validationAndsave();
		}
	};

	const renderForm = () => {
		return (
			<>
				<DialogTitles id="Leave-Assign-dialog-title">
					<MainBox>
						<TitleBox themechange={themeChange ? "true" : "false"}>
							Leave Assign
						</TitleBox>
						<DetailsBox themechange={themeChange ? "true" : "false"}>
							({selectedDataDailog?.year})
						</DetailsBox>
					</MainBox>
					<DetailsBox themechange={themeChange ? "true" : "false"}>
						{updateMonthlyRecord
							? selectedDataDailog?.percentageLeft +
							dates.percentage
							: selectedDataDailog?.percentageLeft}
						% Balance
					</DetailsBox>
				</DialogTitles>
				{isLoading ? (
					<LoadingBox>
						<Loader />
					</LoadingBox>
				) : (<>
					<DialogContents themechange={themeChange ? "true" : "false"}>
						{selectDates || updateMonthlyRecord ? (
							<InnerBoxOne>
								<LabelTypography>
									Start Date
								</LabelTypography>
								<CustomTextField
									// InputProps={{
									// 	startAdornment: (
									// 		<InputAdornment position="start">
									// 			{/* Add your icon here if needed */}
									// 		</InputAdornment>
									// 	),
									// }}
									themechange={themeChange ? "true" : "false"}
									name="startDate"
									value={assignData.startDate}
									type="date"
									onChange={handleInputChange}
									// error={error.startDate}
									// helperText={error.startDate}
									inputProps={{
										min: updateMonthlyRecord
											? new Date(dates.startDate)
												.toISOString()
												.split("T")[0]
											: formattedDate,
										max: limitDate,
										startadornment: (
											<InputAdornment position="start">
												{/* Add your icon here if needed */}
											</InputAdornment>
										),
									}}
								/>
								<ErrorTypography>
									{error.startDate}
								</ErrorTypography>
								<br />
								<LabelTypography>
									End Date
								</LabelTypography>
								<CustomTextField
									// InputProps={{
									// 	startAdornment: (
									// 		<InputAdornment position="start">
									// 			{/* Add your icon here if needed */}
									// 		</InputAdornment>
									// 	),
									// }}
									themechange={themeChange ? "true" : "false"}
									name="endDate"
									value={assignData.endDate}
									type="date"
									onChange={handleInputChange}
									// error={error.endDate}
									// helperText={error.endDate}
									inputProps={{
										min: updateMonthlyRecord
											? new Date(dates.startDate)
												.toISOString()
												.split("T")[0]
											: formattedDate,
										max: limitDate,
										startadornment: (
											<InputAdornment position="start">
												{/* Add your icon here if needed */}
											</InputAdornment>
										),
									}}
								/>
								<ErrorTypography>
									{error.endDate}
								</ErrorTypography>
								<br />
								<LabelTypography>
									Percentage(%)
								</LabelTypography>
								<OutlinedInputs
									fullWidth
									endAdornment={
										<PerInputAdornment
											themechange={themeChange ? "true" : "false"}
											position="end"
										>
											%
										</PerInputAdornment>
									}
									inputProps={{
										startadornment: (
											<InputAdornment position="start">
												{/* Add your icon here if needed */}
											</InputAdornment>
										),
									}}
									name="percentage"
									value={assignData.percentage}
									type="text"
									onChange={handleInputChange}
								// error={error.percentage}
								// helperText={error.percentage}
								/>
								<ErrorTypography>
									{error.percentage}
								</ErrorTypography>
							</InnerBoxOne>
						) : null}

						{selectEmployeeCard ? (
							<SelectEmployeeForLeaves
								setDisplayError={setDisplayError}
								employeeSelectedPreviously={dates?.employees}
								employeeList={employeeList}
								setEmployeeSelectedForLeave={
									setEmployeeSelectedForLeave
								}
								setEmployeeSelectedForLeaveEmpId={
									setEmployeeSelectedForLeaveEmpId
								}
								employeeSelectedForLeaveEmpId={
									employeeSelectedForLeaveEmpId
								}
							/>
						) : null}
					</DialogContents>

					<DialogsActions>
						<Typography sx={{ color: "red" }}>
							{displayError}
						</Typography>
						<Box>
							<CancelButton
								isdarktheme={isDarkTheme ? "true" : "false"}
								onClick={() => {
									setOpenDailogBox(false);
									setError({});
									setAssignData({
										startDate: "",
										endDate: "",
										percentage: "",
									});
									// setSelectEmployee(false);
								}}
							>
								Cancel
							</CancelButton>
							{selectDates ? (
								<Button
									variant="contained"
									color="primary"
									onClick={() => {
										validation();
									}}
								>
									Add Record
								</Button>
							) : null}
							{updateMonthlyRecord ? (
								<Button
									variant="contained"
									color="primary"
									onClick={() => {
										validation();
									}}
								>
									Update Record
								</Button>
							) : null}
							{selectEmployeeCard ? (
								<Button
									variant="contained"
									color="success"
									sx={{}}
									onClick={() => {
										assignLeaves();
									}}
								>
									Assign
								</Button>
							) : null}
						</Box>
					</DialogsActions>
				</>
				)}
			</>)
	}

	useEffect(() => {
		setLimitDate(formattedEndDate);
		setAssignData((prevData) => ({
			...prevData,
			startDate: formattedDate,
		}));
	}, [formattedDate, formattedEndDate]);

	useEffect(() => {
		if (updateMonthlyRecord) {
			const date = new Date(dates.endDate);
			date.setDate(date.getDate() + 1);
			const newDateString = date.toISOString().split("T")[0];
			if (newDateString !== formattedDate) {
				setLimitDate(
					new Date(dates.endDate).toISOString().split("T")[0]
				);
			}
			setAssignData({
				startDate: new Date(dates.startDate)
					.toISOString()
					.split("T")[0],
				endDate: new Date(dates.endDate).toISOString().split("T")[0],
				percentage: dates.percentage,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateMonthlyRecord, dates]);

	return (
		<Box>
			<DialogComponent
				open={open}
				onClose={onClose}
				maxWidth="md"
				fullwidth="true"
				sx={{
					".MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper":
					{
						backgroundColor: isDarkTheme
							? "#0b1727 !important"
							: "#ffffff !important",
						width: "30%",
						height: "max-contant",
					},
				}}
				renderChildern={renderForm}
			/>
		</Box>
		// <Box>
		// 	<Dialog
		// 		open={open}
		// 		onClose={() => {
		// 			setOpenDailogBox(false);
		// 		}}
		// 		maxWidth="md"
		// 		fullwidth="true"
		// 		sx={{
		// 			".MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper":
		// 			{
		// 				backgroundColor: isDarkTheme
		// 					? "#0b1727 !important"
		// 					: "#ffffff !important",
		// 				width: "30%",
		// 				height: "max-contant",
		// 			},
		// 		}}
		// 	>
		// 		<DialogTitle
		// 			id="Leave-Assign-dialog-title"
		// 			sx={{
		// 				display: "flex",
		// 				flexDirection: "row",
		// 				alignItems: "center",
		// 				justifyContent: "space-between",
		// 			}}
		// 		>
		// 			<Box
		// 				sx={{
		// 					display: "flex",
		// 					flexDirection: "row",
		// 					alignItems: "center",
		// 				}}
		// 			>
		// 				<Box
		// 					sx={{
		// 						fontWeight: "bold",
		// 						fontSize: "25px",
		// 						color: themeChange ? "#e0e0e0cc" : "#5e6e82",
		// 						marginRight: "5px",
		// 					}}
		// 				>
		// 					Leave Assign
		// 				</Box>
		// 				<Box
		// 					sx={{
		// 						fontSize: "20px",
		// 						color: themeChange ? "#e0e0e0cc" : "#5e6e82",
		// 					}}
		// 				>
		// 					({selectedDataDailog?.year})
		// 				</Box>
		// 			</Box>
		// 			<Box
		// 				sx={{
		// 					fontSize: "20px",
		// 					color: themeChange ? "#e0e0e0cc" : "#5e6e82",
		// 				}}
		// 			>
		// 				{updateMonthlyRecord
		// 					? selectedDataDailog?.percentageLeft +
		// 					dates.percentage
		// 					: selectedDataDailog?.percentageLeft}
		// 				% Balance
		// 			</Box>
		// 		</DialogTitle>
		// 		{isLoading ? (
		// 			<Box
		// 				sx={{
		// 					display: "flex",
		// 					justifyContent: "center",
		// 					alignItems: "center",
		// 					height: "42vh",
		// 				}}
		// 			>
		// 				<Loader />
		// 			</Box>
		// 		) : (<>
		// 			<DialogContent
		// 				sx={{ color: themeChange ? "#e0e0e0cc" : "#5e6e82" }}
		// 			>
		// 				{selectDates || updateMonthlyRecord ? (
		// 					<Box
		// 						sx={{
		// 							display: "flex",
		// 							flexDirection: "column",
		// 							flexWrap: "wrap",
		// 							alignContent: "center",
		// 							justifyContent: "center",
		// 							alignItems: "center",
		// 						}}
		// 					>
		// 						<Typography sx={{ fontSize: "115%", alignSelf: "baseline" }}>
		// 							Start Date
		// 						</Typography>
		// 						<TextField
		// 							className={
		// 								!themeChange ? null : classes.whiteDateIcon
		// 							}
		// 							InputProps={{
		// 								startAdornment: (
		// 									<InputAdornment position="start">
		// 										{/* Add your icon here if needed */}
		// 									</InputAdornment>
		// 								),
		// 							}}
		// 							sx={{
		// 								width: "-webkit-fill-available",
		// 								height: "fit-content",
		// 								background: themeChange
		// 									? "#142840"
		// 									: "#ffffff",
		// 							}}
		// 							name="startDate"
		// 							value={assignData.startDate}
		// 							type="date"
		// 							onChange={handleInputChange}
		// 							// error={error.startDate}
		// 							// helperText={error.startDate}
		// 							inputProps={{
		// 								min: updateMonthlyRecord
		// 									? new Date(dates.startDate)
		// 										.toISOString()
		// 										.split("T")[0]
		// 									: formattedDate, // Restrict selection of earlier dates
		// 								max: limitDate,
		// 							}}
		// 						/>
		// 						<Typography
		// 							sx={{
		// 								color: "red",
		// 								marginTop: "2px",
		// 								alignSelf: "baseline",
		// 							}}
		// 						>
		// 							{error.startDate}
		// 						</Typography>
		// 						<br />
		// 						<Typography
		// 							sx={{ fontSize: "115%", alignSelf: "baseline" }}
		// 						>
		// 							End Date
		// 						</Typography>
		// 						<TextField
		// 							className={
		// 								!themeChange ? null : classes.whiteDateIcon
		// 							}
		// 							InputProps={{
		// 								startAdornment: (
		// 									<InputAdornment position="start">
		// 										{/* Add your icon here if needed */}
		// 									</InputAdornment>
		// 								),
		// 							}}
		// 							sx={{
		// 								width: "-webkit-fill-available",
		// 								height: "fit-content",
		// 								background: themeChange
		// 									? "#142840"
		// 									: "#ffffff",
		// 							}}
		// 							name="endDate"
		// 							value={assignData.endDate}
		// 							type="date"
		// 							onChange={handleInputChange}
		// 							// error={error.endDate}
		// 							// helperText={error.endDate}
		// 							inputProps={{
		// 								min: updateMonthlyRecord
		// 									? new Date(dates.startDate)
		// 										.toISOString()
		// 										.split("T")[0]
		// 									: formattedDate, // Restrict selection of earlier dates
		// 								max: limitDate,
		// 							}}
		// 						/>
		// 						<Typography
		// 							sx={{
		// 								color: "red",
		// 								marginTop: "2px",
		// 								alignSelf: "baseline",
		// 							}}
		// 						>
		// 							{error.endDate}
		// 						</Typography>
		// 						<br />
		// 						<Typography
		// 							sx={{ fontSize: "115%", alignSelf: "baseline" }}
		// 						>
		// 							Percentage(%)
		// 						</Typography>
		// 						<OutlinedInput
		// 							fullWidth
		// 							endAdornment={
		// 								<InputAdornment
		// 									sx={{
		// 										".MuiTypography-root": {
		// 											fontSize: "160%",
		// 											color: themeChange
		// 												? "#e0e0e0cc !important"
		// 												: "#5e6e82 !important",
		// 										},
		// 									}}
		// 									position="end"
		// 								>
		// 									%
		// 								</InputAdornment>
		// 							}
		// 							InputProps={{
		// 								startAdornment: (
		// 									<InputAdornment position="start">
		// 										{/* Add your icon here if needed */}
		// 									</InputAdornment>
		// 								),
		// 							}}
		// 							sx={{
		// 								width: "-webkit-fill-available",
		// 								height: "fit-content",
		// 								// background: themeChange ? "#142840" : "#ffffff",
		// 							}}
		// 							name="percentage"
		// 							value={assignData.percentage}
		// 							type="text"
		// 							onChange={handleInputChange}
		// 						// error={error.percentage}
		// 						// helperText={error.percentage}
		// 						/>
		// 						<Typography
		// 							sx={{
		// 								color: "red",
		// 								marginTop: "2px",
		// 								alignSelf: "baseline",
		// 							}}
		// 						>
		// 							{error.percentage}
		// 						</Typography>
		// 					</Box>
		// 				) : null}

		// 				{selectEmployeeCard ? (
		// 					<SelectEmployeeForLeaves
		// 						setDisplayError={setDisplayError}
		// 						employeeSelectedPreviously={dates?.employees}
		// 						employeeList={employeeList}
		// 						setEmployeeSelectedForLeave={
		// 							setEmployeeSelectedForLeave
		// 						}
		// 						setEmployeeSelectedForLeaveEmpId={
		// 							setEmployeeSelectedForLeaveEmpId
		// 						}
		// 						employeeSelectedForLeaveEmpId={
		// 							employeeSelectedForLeaveEmpId
		// 						}
		// 					/>
		// 				) : null}
		// 			</DialogContent>

		// 			<DialogActions
		// 				sx={{ display: "flex", justifyContent: "space-between" }}
		// 			>
		// 				<Typography sx={{ color: "red" }}>
		// 					{displayError}
		// 				</Typography>
		// 				<Box>
		// 					<Button
		// 						sx={{
		// 							color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
		// 							marginRight: "10px",
		// 						}}
		// 						onClick={() => {
		// 							setOpenDailogBox(false);
		// 							setError({});
		// 							setAssignData({
		// 								startDate: "",
		// 								endDate: "",
		// 								percentage: "",
		// 							});
		// 							setSelectEmployee(false);
		// 						}}
		// 					>
		// 						Cancel
		// 					</Button>
		// 					{selectDates ? (
		// 						<Button
		// 							variant="contained"
		// 							color="primary"
		// 							onClick={() => {
		// 								validation();
		// 							}}
		// 						>
		// 							Add Record
		// 						</Button>
		// 					) : null}
		// 					{updateMonthlyRecord ? (
		// 						<Button
		// 							variant="contained"
		// 							color="primary"
		// 							onClick={() => {
		// 								validation();
		// 							}}
		// 						>
		// 							Update Record
		// 						</Button>
		// 					) : null}
		// 					{selectEmployeeCard ? (
		// 						<Button
		// 							variant="contained"
		// 							color="success"
		// 							sx={{}}
		// 							onClick={() => {
		// 								assignLeaves();
		// 							}}
		// 						>
		// 							Assign
		// 						</Button>
		// 					) : null}
		// 				</Box>
		// 			</DialogActions>
		// 		</>
		// 		)}
		// 	</Dialog>
		// </Box>
	);
}

export default LeaveAssignCard;
