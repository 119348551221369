import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Box,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import Loader from "../Loader/Loader";
import { setToast, setToastMsg, setToastType } from "../../Slices/toastSlice";
import {
  SaveButton,
  FooterBox,
  TitleTypography,
  LoadingBox
} from "../../Styles/Configuration/ConfigurationPageStyle";
import {
  fetchConfiguration,
  saveConfiguration,
} from "../../Services/ConfigurationServices";

const staticSaturdays = [
  "1st Saturday",
  "2nd Saturday",
  "3rd Saturday",
  "4th Saturday",
  "5th Saturday",
];

const ConfigurationPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);

  const [selectedSaturdays, setSelectedSaturdays] = useState([]);
  const [displayError, setDisplayError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getConfiguration = async () => {
      setIsLoading(true);
      try {
        const response = await fetchConfiguration();
        if (response.data.success) {
          setIsLoading(false);
          const { activeSaturdays } = response?.data?.data;
          const selected = Object.keys(activeSaturdays).filter(
            (day) => activeSaturdays[day]
          );
          setSelectedSaturdays(selected);
        } else {
          setIsLoading(false);
          setDisplayError(response.data.message);
        }
      } catch (error) {
        setIsLoading(false);
        setDisplayError("Failed to fetch configuration");
      }
    };

    getConfiguration();
  }, []);

  const handleCheckboxChange = (label) => {
    setSelectedSaturdays((prev) =>
      prev?.includes(label) ? prev?.filter((s) => s !== label) : [...prev, label]
    );
  };

  const handleSave = async () => {
    try {
      setDisplayError("");
      const postData = { selectedSaturdays };
      const response = await saveConfiguration(postData);

      if (response.status === 200) {
        dispatch(setToast(true));
        dispatch(
          setToastMsg(
            response.data.message ?? "Active Saturdays Changed Successfully."
          )
        );
        dispatch(setToastType("success"));
      } else {
        setDisplayError(response.data.message);
      }
    } catch (error) {
      setDisplayError("Failed to save configuration");
    }
  };

  return (
        <Box>
      {isLoading ? (
        <LoadingBox>
          <Loader />
        </LoadingBox>
      ) : (
      <Box>
        <Grid container spacing={2} sx={{ padding: "20px" }}>
          <Grid item xs={12}>
            <TitleTypography variant="h6">Working Saturday Configuration</TitleTypography>
          </Grid>
          <Grid item xs={12}>
            <Box>
              {staticSaturdays?.map((saturday) => (
                <FormControlLabel
                  key={saturday}
                  control={
                    <Checkbox
                      sx={{
                        color: isDarkTheme === "true" ? "#e0e0e0cc" : "var(--secondary-text-color)",
                      }}
                      checked={selectedSaturdays?.includes(saturday)}
                      onChange={() => handleCheckboxChange(saturday)}
                    />
                  }
                  label={saturday}
                  sx={{
                  '& .MuiFormControlLabel-label': {
                    color: isDarkTheme === "true" ? "#e0e0e0cc" : "var(--secondary-text-color)",
                  },
                }}
                />
              ))}
            </Box>
          </Grid>
          <FooterBox>
            <Typography sx={{ color: "red" }}>{displayError}</Typography>{" "}
            <Box>
              <Button
                sx={{
                  fontWeight: "bold",
                  color: "gray",
                }}
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                Cancel
              </Button>
              <SaveButton variant="contained" onClick={handleSave}>
                Save
              </SaveButton>
            </Box>
          </FooterBox>
        </Grid>
    </Box>
  )}
  </Box>
  );
};

export default ConfigurationPage;
