import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Backdrop,
  Box,
  Button,
  Slide,
  Tooltip,
  Zoom,
  useMediaQuery,
  IconButton,
} from "@mui/material";

import AddTaskForm from "./AddTaskForm";
import usePermissions from "../RolePermission/usePermissions";
import EmployeeAccordion from "./EmployeeAccordion";
import { setIsShowTask } from "../../Slices/taskSlice";
import EmpTaskDataGrid from "../AdminDashboard/Tables/EmpTaskDataGrid";
import {
  MainBox,
  TasksButton,
  EmpActivityBox,
  SideBox,
  AddTaskBox,
  TitleTypography,
  EmployeeActivityButton,
  TitleBox,
  AddTaskButtonBox,
  SliderBox,
  InnerSliderBox,
  AddTaskButton,
} from "../../Styles/TaskManagement/TaskManagementStyle";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

function TaskManagement() {
  const dispatch = useDispatch();
  // const projects = useSelector((state) => state.employeeData.projectsName);
  const isShowTask = useSelector((state) => state.taskData.isShowTask);
  const user = useSelector((state) => state.selectedActiveView.role);
  const { hasPermission } = usePermissions();
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);

  const [taskAdded, setTaskAdded] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [change, setChange] = useState(false);
  const [selectedButton, setSelectedButton] = useState("Tasks");
  const isMobile = useMediaQuery("(max-width:600px)");
  const scrollRef = useRef(null);

  // const employeeDisplay = user === "employee";
  const adminLogin = user === "admin";

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };
  const toggleForm = () => {
    setShowForm(!showForm);
    // open(showForm);
  };

  const getScrollAmount = () => {
    if (scrollRef.current) {
      return scrollRef.current.offsetWidth * 1.05;
    }
    return 700;
  };

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: -getScrollAmount(),
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: getScrollAmount(),
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (isShowTask === true) {
      setSelectedButton("Employee Activity");
      dispatch(setIsShowTask(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowTask]);

  return (
    <Box>
      {adminLogin ? (
        <MainBox bottom={0}>
          {isMobile ? (
            <>
              <AddTaskButtonBox>
                {hasPermission("Add Task") && (
                  <Box>
                    <Tooltip
                      title="Add New Task"
                      arrow
                      disableInteractive
                      TransitionComponent={Zoom}
                    >
                      <AddTaskButton
                        variant="contained"
                        color="primary"
                        onClick={toggleForm}
                      >
                        + Add Task
                      </AddTaskButton>
                    </Tooltip>
                  </Box>
                )}
              </AddTaskButtonBox>
              <SliderBox
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexGrow={1}
                overflow="hidden"
                position="relative"
              >
                <IconButton onClick={scrollLeft} style={{
                  marginRight: 12,
                  color: isDarkTheme === "true" ? "#e0e0e0cc" : "#5e6e82",
                }}>
                  <ChevronLeftIcon />
                </IconButton>
                <InnerSliderBox
                  ref={scrollRef}
                  display="flex"
                  flexDirection="row"
                  overflowX="auto"
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    flexShrink="0"
                    minWidth="100%"
                    justifyContent="center"
                  >
                    {hasPermission("Tasks") && (
                      <TasksButton
                        onClick={() => handleButtonClick("Tasks")}
                        selectedbutton={selectedButton}
                      >
                        Tasks
                      </TasksButton>
                    )}
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    flexShrink="0"
                    minWidth="100%"
                    justifyContent="center"
                  >
                    {hasPermission("Employee Activity") && (
                      <EmployeeActivityButton
                        onClick={() => handleButtonClick("Employee Activity")}
                        selectedbutton={selectedButton}
                      >
                        Employee Activity
                      </EmployeeActivityButton>
                    )}
                  </Box>
                </InnerSliderBox>
                <IconButton onClick={scrollRight} style={{
                  marginLeft: 12,
                  color: isDarkTheme === "true" ? "#e0e0e0cc" : "#5e6e82",
                }}>
                  <ChevronRightIcon />
                </IconButton>
              </SliderBox>
            </>
          ) : (
            <>
              <Box>
                {hasPermission("Tasks") && (
                  <TasksButton
                    onClick={() => handleButtonClick("Tasks")}
                    selectedbutton={selectedButton}
                  >
                    Tasks
                  </TasksButton>
                )}
                {hasPermission("Employee Activity") && (
                  <EmployeeActivityButton
                    onClick={() => handleButtonClick("Employee Activity")}
                    selectedbutton={selectedButton}
                  >
                    Employee Activity
                  </EmployeeActivityButton>
                )}
              </Box>

              {hasPermission("Add Task") && (
                <Box sx={{ paddingRight: "15px" }}>
                  <Tooltip
                    title="Add New Task"
                    arrow
                    disableInteractive
                    TransitionComponent={Zoom}
                  >
                    <AddTaskButton
                      variant="contained"
                      color="primary"
                      onClick={toggleForm}
                    >
                      + Add Task
                    </AddTaskButton>
                  </Tooltip>
                </Box>
              )}
            </>
          )}
        </MainBox>
      ) : (
        <TitleBox bottom={0}>
          <TitleTypography variant="h6">Tasks</TitleTypography>
          {hasPermission("Add Task") && (
            <AddTaskBox>
              <Tooltip
                title="Add New Task"
                arrow
                disableInteractive
                TransitionComponent={Zoom}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={toggleForm}
                >
                  + Add Task
                </Button>
              </Tooltip>
            </AddTaskBox>
          )}
        </TitleBox>
      )}
      {hasPermission("Tasks") && selectedButton === "Tasks" ? (
        <>
          <Box>
            <EmployeeAccordion
              change={change}
              setChange={setChange}
              taskAdded={taskAdded}
            />
          </Box>
          <Box>
            <Slide direction="left" in={showForm} mountOnEnter unmountOnExit>
              <SideBox>
                <AddTaskForm
                  onClose={toggleForm}
                  setChange={setChange}
                  change={change}
                  taskAdded={taskAdded}
                  setTaskAdded={setTaskAdded}
                />
              </SideBox>
            </Slide>
            <Backdrop open={showForm} onClick={toggleForm} />
          </Box>
        </>
      ) : null}
      {hasPermission("Employee Activity") &&
        selectedButton === "Employee Activity" ? (
        <EmpActivityBox overflow="auto">
          {/* <EmpTaskTable showButtun={true} /> */}
          <EmpTaskDataGrid showButtun={true} />
        </EmpActivityBox>
      ) : null}
    </Box>
  );
}

export default TaskManagement;
