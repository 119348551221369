import React from "react";
import {
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";

import {
	empTaskTableBodyCell,
	empTaskTableHeadCell,
} from "./CustomDesignMUI/CustomMUI";

function TableComponent(props) {
	const { array, openModal, selectedLeaveData, option, showBalance } = props;
	return (
		<>
			{array?.map((data, index) => {
				const leaveTypes = data?.map((item) => item?.leaveType);
				const allotteds = data?.map((item) => item?.allotted);
				const balances = data?.map((item) => item?.balance);
				return (
					<TableContainer
						key={index}
						sx={{
							backgroundColor: "var(--event-calendar-color)",
						}}
					>
						<Table sx={{
							marginBottom: "2%",
							'@media (max-width: 600px)': {
								width: showBalance ? '420%' : "360%",
							},
						}}>
							<TableHead key={index}>
								<TableRow sx={{ background: "var(--background-table-borderHeader-color)" }}>
									{leaveTypes?.map((item, idx) => (
										<TableCell key={idx} sx={{ ...empTaskTableHeadCell }}>
											{item}
											{/* Check if this is the last item */}
											{idx === leaveTypes?.length - 1 && showBalance && (
												<Button
													onClick={() => openModal(option, leaveTypes, selectedLeaveData)}
													variant="outlined"
													size="small"
													sx={{ marginLeft: 2 }}
												>
													Round Off
												</Button>
											)}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow>
									{allotteds?.map((allotted, idx) => (
										<TableCell sx={{ ...empTaskTableBodyCell }} key={idx}>
											{allotted}
										</TableCell>
									))}
								</TableRow>
								<TableRow>
									{balances?.map((balance, idx) => (
										<TableCell sx={{ ...empTaskTableBodyCell }} key={idx}>
											{balance}
										</TableCell>
									))}
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				);
			})}
		</>
	);
}
export default TableComponent;
