import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Box,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  setToast,
  setToastMsg,
  setToastType,
} from "../../../Slices/toastSlice";
import { formatReverseDate } from "../../ReusableComponents/UtilityFunctions";
import { viewProfileTitle } from "../../ReusableComponents/CustomDesignMUI/CustomMUI";
import CustomDialogleave from "../../ReusableComponents/CustomDialogleave";
import { setAllLeaveData } from "../../../Slices/leaveData";
import { setisLeave } from "../../../Slices/selectedActive";
import ExitDialog from "../ExitDialog";
import Loader from "../../Loader/Loader";
import { errorMessage } from "../../../Services/axiosInstance";
import { getLeaveBalanace, upsertLeave } from "../../../Services/LeaveManageServices";
import { MainBox, LoadingBox, SaveButton, CancelButton, FooterBox, TitleBox, CustomTextField, CustomFormControl, EmployeeNameTextField } from "../../../Styles/LeaveManagement/AddLeaveFormStyle"

const AddLeaveForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const LeavesType = useSelector(
    (state) => state.employeeLeaveData.leavesTypes
  );
  const allData = useSelector((state) => state.employeeData.value);
  const user = useSelector((state) => state.selectedActiveView.role);
  const currentEmployee = useSelector((state) => state.addEmployee.current);
  const warning = [];
  const EmployeeLogin = user === "employee";
  const adminLogin = user === "admin";
  const EmployeeName = currentEmployee?.firstName;
  const EmployeeId = currentEmployee?.empId;

  const [displayError, setDisplayError] = useState("");
  const [error, setError] = useState([]);
  const [LeaveTime, setLeaveTime] = useState(false);
  const [openExitDialog, setOpenExitDialog] = useState(false);
  const [warningArray, setWarningArray] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [LeaveTypeBalance, setLeaveTypeBalance] = useState(LeavesType);
  const [leaveData, setLeaveData] = useState({
    empId: EmployeeLogin ? EmployeeId : "",
    employeeName: EmployeeLogin ? EmployeeName : "",
    leaveType: "",
    reason: "",
    startDate: "",
    endDate: "",
    leaveTime: "",
  });
  const leaveTimeOptions = ["Full day", "Half day", "Early Leave(2hours max)"];
  const startDateObj = new Date(leaveData.startDate);
  const endDateObj = new Date(leaveData.endDate);
  const currentDateObj = new Date();
  const oneDay = 24 * 60 * 60 * 1000;
  const startDateUTC = Date.UTC(
    startDateObj.getFullYear(),
    startDateObj.getMonth(),
    startDateObj.getDate()
  );
  const endDateUTC = Date.UTC(
    endDateObj.getFullYear(),
    endDateObj.getMonth(),
    endDateObj.getDate()
  );
  const currentDateUTC = Date.UTC(
    currentDateObj.getFullYear(),
    currentDateObj.getMonth(),
    currentDateObj.getDate()
  );
  const leaveDuration = Math.floor((endDateUTC - startDateUTC) / oneDay) + 1;
  const daysForLeave = Math.floor((startDateUTC - currentDateUTC) / oneDay);

  const handleCloseCancelDialog = async () => {
    setOpenExitDialog(false);
  };

  const handleSelect = (event, value) => {
    setLeaveData((prevLeaveData) => ({
      ...prevLeaveData,
      employeeName: value,
    }));

    if (value) {
      const selectedEmployee = allData.find(
        (employee) => employee.FirstName === value
      );
      if (selectedEmployee) {
        setLeaveData((prevLeaveData) => ({
          ...prevLeaveData,
          empId: selectedEmployee.empId,
        }));
      }
    }
  };

  const saveLeaveRecord = async () => {
    setDisplayError("");
    const error = [];
    if (!leaveData.employeeName) {
      error.employeeName = "employee name is required.";
    } else if (!leaveData.leaveType) {
      error.leaveType = "Type of leave is required.";
    } else if (!leaveData.startDate) {
      error.startDate = "Date is required.";
    } else if (new Date(leaveData.startDate) < currentDateUTC) {
      error.startDate = "Invalid Date.";
    } else if (!leaveData.endDate) {
      error.endDate = "Date is required.";
    } else if (leaveData.endDate < leaveData.startDate) {
      error.endDate = "Invalid Date.";
    } else if (!leaveData.reason) {
      error.reason = "Reason is required.";
    } else if (!leaveData.leaveTime && LeaveTime) {
      error.leaveTime = "leave time is required.";
    }
    setError(error);
    const noErrors = Object.keys(error).length === 0;
    if (noErrors) {
      const type = LeaveTypeBalance?.filter(
        (state) => state._id === leaveData.leaveType
      );
      if (type.leaveType === "Sick Leave") {
        if (daysForLeave > 1) {
          warning.push("For sick leave advance leave request is not allowed.");
        }
      } else {
        if (leaveDuration === 1 && leaveData.leaveTime === "Half day") {
          if (daysForLeave < 4) {
            warning.push(
              "For half day leave, 3 days prior leave request is needed."
            );
          }
        } else if (
          leaveDuration === 1 &&
          leaveData.leaveTime === "Early Leave(2hours max)"
        ) {
          if (daysForLeave < 1) {
            warning.push(
              "For early leave, 1 day prior leave request is needed."
            );
          }
        } else if (
          (leaveDuration === 1 && leaveData.leaveTime === "Full day") ||
          leaveDuration === 2
        ) {
          if (daysForLeave < 8) {
            warning.push(
              "For 1-2 day leave, one week prior leave request is needed."
            );
          }
        } else if (leaveDuration < 6 && leaveDuration > 2) {
          if (daysForLeave < 16) {
            warning.push(
              "For 3-5 days leave, 15 days prior leave request is needed."
            );
          }
        } else if (leaveDuration > 6) {
          if (daysForLeave < 31) {
            warning.push(
              "For one week leave, one Month prior leave request is needed."
            );
          }
        }
      }

      setWarningArray(warning);
      if (warning.length) {
        setOpenDialog(true);
      } else {
        setIsLoading(true);
        try {
          const postData = JSON.stringify(leaveData)
          const response = await upsertLeave(postData)
          if (response.status === 201 || response.status === 200) {
            setIsLoading(false);
            dispatch(setAllLeaveData(response.data.leave));
            dispatch(setisLeave(true));
            if (EmployeeLogin) {
              navigate("/leaves");
            } else if (adminLogin) {
              navigate("/leave-management");
            }
            dispatch(setToast(true));
            dispatch(setToastMsg(response.data.message ?? "leave record added!"));
            dispatch(setToastType("success"));
          }
        } catch (error) {
          setIsLoading(false);
          setDisplayError(errorMessage ?? "")
        }
      }
    }
  };

  const handleInputChange = (event) => {
    setDisplayError("");
    const { name, value } = event.target;
    setLeaveData((prevLeaveData) => ({
      ...prevLeaveData,
      [name]: value,
    }));
  };

  const checkLeaveTime = (endDate) => {
    if (endDate) {
      const isSameDate = leaveData.startDate === endDate;
      setLeaveTime(isSameDate);
    }
  };

  const handleSelectLeaveType = (event) => {
    const { value } = event.target;
    setLeaveData((prevLeaveData) => ({
      ...prevLeaveData,
      leaveType: value,
    }));
  };

  const handleSelectLeaveTime = (event) => {
    const { value } = event.target;
    setLeaveData((prevLeaveData) => ({
      ...prevLeaveData,
      leaveTime: value,
    }));
  };

  const onBackClick = () => {
    if (EmployeeLogin) {
      navigate("/leaves");
    } else if (adminLogin) {
      navigate("/leave-management");
    }
  };

  const getleaveBalanaceData = async (data) => {
    if (data) {
      try {
        const employeeEmpId = adminLogin ? leaveData.empId : EmployeeId;
        const response = await getLeaveBalanace(data, employeeEmpId);
        if (response.status === 200 || response.status === 201) {
          const viewLeavesArray = response?.data?.viewLeavesArray;
          const leaveTypes = viewLeavesArray?.map(item => item?.leaveType);
          const balanceRow = viewLeavesArray?.map(item => item?.balance);

          const leaveBalanceMap = leaveTypes?.reduce((acc, leaveType, index) => {
            acc[leaveType] = balanceRow[index];
            return acc;
          }, {});
          const updatedLeaveTypeBalance = LeaveTypeBalance?.map(item => {
            const balance = leaveBalanceMap[item?.leaveType];
            return {
              ...item,
              balance: balance !== undefined ? balance : null
            };
          });
          setLeaveTypeBalance(updatedLeaveTypeBalance)
        }
        else {
          setLeaveTypeBalance([])
        }
      } catch (error) {
      }
    }
  };

  const reset = () => {
    setLeaveData({
      empId: EmployeeLogin ? EmployeeId : "",
      employeeName: EmployeeLogin ? EmployeeName : "",
      leaveType: "",
      reason: "",
      startDate: "",
      endDate: "",
    });
    setError("");
  };

  useEffect(() => {
    if ((adminLogin || EmployeeLogin) && leaveData?.empId !== '') {
      const date = formatReverseDate(new Date());
      getleaveBalanaceData(date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaveData]);

  useEffect(() => {
    checkLeaveTime(leaveData.endDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaveData.startDate, leaveData.endDate]);

  return (
    <MainBox>
      {isLoading ? (
        <LoadingBox>
          <Loader />
        </LoadingBox>
      ) : (
        <Box>
          <Box>
            <TitleBox>
              <Typography sx={{ ...viewProfileTitle }}>
                <IconButton onClick={onBackClick}>
                  <ArrowBackIcon color="primary" />
                </IconButton>
                Add Leave
              </Typography>
            </TitleBox>
            <Box>
              <Box sx={{ padding: "0px 0px 0px 20px" }}>
                <Box sx={{ marginBottom: "15px" }}>
                  {adminLogin ? (
                    <>
                      <Autocomplete
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        options={
                          allData?.map((option) => option.FirstName) || []
                        }
                        getOptionLabel={(option) => option}
                        value={leaveData.employeeName || null}
                        onChange={(event, newValue) =>
                          handleSelect(event, newValue)
                        }
                        renderInput={(params) => (
                          <EmployeeNameTextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                            }}
                            value={leaveData.employeeName || ""}
                            name="employeeName"
                            label="Employee Name"
                          />
                        )}
                      />
                      {error.employeeName && (
                        <Typography color="error">
                          {error.employeeName}
                        </Typography>
                      )}
                    </>
                  ) : null}

                  <CustomFormControl>
                    <InputLabel id="leaveType-label">Leave Type</InputLabel>
                    <Select
                      labelId="leaveType-label"
                      id="leaveType"
                      label="Leave Type"
                      value={leaveData.leaveType}
                      onChange={(event) => handleSelectLeaveType(event)}
                    >
                      {LeaveTypeBalance?.map((option) => (
                        <MenuItem key={option._id} value={option._id}>
                          {option?.balance ? `${option?.leaveType} - ${option?.balance}` : option?.leaveType}
                        </MenuItem>
                      ))}
                    </Select>
                    {error.leaveType && (
                      <Typography color="error">{error.leaveType}</Typography>
                    )}
                  </CustomFormControl>
                  <CustomTextField
                    isdarktheme={isDarkTheme ? "true" : "false"}
                    InputProps={{
                      startAdornment: <InputAdornment position="start" />,
                    }}
                    onChange={(event) => handleInputChange(event)}
                    value={leaveData.startDate}
                    name="startDate"
                    label="From Date"
                    type="date"
                  />
                  {error.startDate && (
                    <Typography color="error">{error.startDate}</Typography>
                  )}
                  <CustomTextField
                    isdarktheme={isDarkTheme ? "true" : "false"}
                    InputProps={{
                      startAdornment: <InputAdornment position="start" />,
                    }}
                    onChange={(event) => {
                      handleInputChange(event);
                      checkLeaveTime(event.target.value);
                    }}
                    value={leaveData.endDate}
                    name="endDate"
                    label="To Date"
                    type="date"
                  />
                  {error.endDate && (
                    <Typography color="error">{error.endDate}</Typography>
                  )}
                  {LeaveTime ? (
                    <>
                      <CustomFormControl>
                        <InputLabel id="leaveType-label">
                          Leave Duration
                        </InputLabel>
                        <Select
                          labelId="leaveType-label"
                          id="leaveTime"
                          label="Leave duration"
                          value={leaveData.leaveTime}
                          onChange={(event) => handleSelectLeaveTime(event)}
                        >
                          {leaveTimeOptions?.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                        {error.leaveTime && (
                          <Typography color="error">
                            {error.leaveTime}
                          </Typography>
                        )}
                      </CustomFormControl>
                    </>
                  ) : null}
                  <CustomTextField
                    name="reason"
                    label="Leave Reason"
                    value={leaveData.reason}
                    onChange={(event) => handleInputChange(event)}
                    multiline
                    rows={4}
                  />
                  {error.reason && (
                    <Typography color="error">{error.reason}</Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <FooterBox>
              <Typography sx={{ color: "red" }}>{displayError}</Typography>{" "}
              <Box>
                <CancelButton
                  onClick={() => {
                    setOpenExitDialog(true);
                  }}
                >
                  Cancel
                </CancelButton>
                <ExitDialog
                  open={openExitDialog}
                  reset={reset}
                  onClose={handleCloseCancelDialog}
                />
                <SaveButton
                  variant="contained"
                  onClick={saveLeaveRecord}
                >
                  Save
                </SaveButton>
              </Box>
            </FooterBox>
            <CustomDialogleave
              open={openDialog}
              setDisplayError={setDisplayError}
              setIsLoading={setIsLoading}
              setOpenDialog={setOpenDialog}
              dialogHeading="Confirm Leave Request"
              leaveData={leaveData}
              dialogDescription="Are you sure you want to apply for leave?"
              warning={warningArray}
              dialogIcon={
                <WarningAmberIcon
                  sx={{
                    color: "#DAA520",
                    fontSize: "30px",
                    backgroundColor: isDarkTheme ? "#121e2e" : "#ffffff",
                    borderBlockColor: isDarkTheme ? "#121e2e" : "#ffffff",
                  }}
                />
              }
            />
          </Box>
        </Box>
      )}
    </MainBox>
  );
};

export default AddLeaveForm;
