import { axiosInstance } from './axiosInstance';

export const getAllPermissionsData = () => axiosInstance.get('/admin/getAllPermissions');

export const getRoleByPermissionsData = () => axiosInstance.get('/admin/getRoleByPermission');

export const addRoleByPermissions = (postData) => axiosInstance.post('/admin/addRoleByPermission', postData);

export const fetchConfiguration = () => axiosInstance.get("/admin/getConfiguration");

export const saveConfiguration = (postData) => axiosInstance.post("/admin/saveConfiguration", postData);
