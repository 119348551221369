import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  TextField,
  Button,
  useMediaQuery
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CryptoJS from "crypto-js";

import { setAllData } from "../../../Slices/UserDataSlice";
import {
  setToast,
  setToastMsg,
  setToastType,
} from "../../../Slices/toastSlice";
import {
  setemployeeAllData,
  // setemployeeAllLeaveData,
} from "../../../Slices/employeeDetailSlice";
import { setLeavesTypes } from "../../../Slices/leaveData";
import { setRole, setGetRoleByPermission, setGetNotificationCount, setEmployeeName } from "../../../Slices/selectedActive";
import {
  InputField,
  InputFieldProps,
} from "../../ReusableComponents/CustomDesignMUI/CustomMUI";
import { formatDate } from "../../ReusableComponents/UtilityFunctions";
import { ReactComponent as BrandLogo } from "../../../assets/img/icons/EmployeeVueLogo.svg";
import LoginImage from "./../../../assets/img/AdminLoginSVG.svg";
import Loader from "../../Loader/Loader";
import { upsertLogin } from "../../../Services/Authentication";
import {
  ErrorMessageTypography,
  ErrorTypography,
  FooterMainBox,
  InnerBoxOne,
  InnerBoxTwo,
  InputBox,
  LinkTypography,
  LoadingBox,
  LoginBox,
  LoginButton,
  MainBox,
  MainGrid,
  MainFormBox,
  TextTypography,
  SpaceTypography,
} from "../../../Styles/Authentication/LoginStyle";
import "./Login.scss";

const Login = () => {
  const is_JT_theme_Dark =
    window.localStorage.getItem("is_JT_theme_Dark") === "true";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const presentDate = formatDate(new Date());
  const previousDate = window.localStorage.getItem("presentDay");

  const validateEmail = useCallback((email) => {
    if (!/\S+@\S+\.\S+/.test(email)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        email: "Please enter a valid email address",
      }));
      return false;
    }
    setFormErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    return true;
  }, []);

  const validatePassword = useCallback((password) => {
    if (password.length < 6) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        password: "Password must be at least 6 characters",
      }));
      return false;
    }
    setFormErrors((prevErrors) => ({ ...prevErrors, password: "" }));
    return true;
  }, []);

  const handleChange = useCallback((e) => {
    setErrorMessage("");
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const is_JT_theme_Dark = localStorage.getItem("is_JT_theme_Dark");
      window.localStorage.clear();
      localStorage.setItem("is_JT_theme_Dark", is_JT_theme_Dark);
      if (presentDate !== previousDate) {
        window.localStorage.removeItem("presentDay");
        window.localStorage.removeItem("totalTime");
        window.localStorage.removeItem("punchIn");
        window.localStorage.removeItem("start");
        window.localStorage.removeItem("totalBreakTime");
        window.localStorage.removeItem("TaskNo");
      }

      const { email, password } = formData;
      const isEmailValid = validateEmail(email);
      const isPasswordValid = validatePassword(password);

      if (isEmailValid && isPasswordValid) {
        setIsLoading(true);
        try {
          // const postData = JSON.stringify({ email, password });
          const encryptedPassword = CryptoJS.AES.encrypt(
            password,
            "JT123"
          ).toString();

          const postData = JSON.stringify({
            email,
            password: encryptedPassword,
          });
          const response = await upsertLogin(postData);
          // let response;
          if (response.status === 200) {
            setErrorMessage("");
            const adminData = response.data.User;
            const data = response.data.leavesTypeArray;
            window.localStorage.setItem("Token", adminData.token);
            const presentDay = response?.data?.presentDay;
            const getRoleByPermission = response?.data?.getRoleByPermission;
            const setStart = response?.data?.start;
            // window.localStorage.setItem("getRoleByPermission", JSON.stringify(getRoleByPermission));
            dispatch(setEmployeeName(adminData?.firstName))
            dispatch(setGetRoleByPermission(getRoleByPermission));
            dispatch(setGetNotificationCount(response?.data?.notificationCount))
            dispatch(setLeavesTypes(data));
            dispatch(setToast(true));
            dispatch(setToastMsg("Login Successfully!"));
            dispatch(setToastType("success"));

            if (["HR", "CEO"].includes(response.data.position)) {
              const allUserData = response.data.allUserData;
              dispatch(setRole("admin"));
              dispatch(setAllData(allUserData));

              if (!window.localStorage.getItem("start")) {
                window.localStorage.setItem("presentDay", presentDay);
                window.localStorage.setItem("start", setStart);
              }
              navigate("/dashboard");
            } else {
              const employee = response.data.employeeDetails;
              // const Leaves = response.data.leave;
              const allUserData = response.data.allUserData;
              dispatch(setRole("employee"));
              dispatch(setAllData(allUserData));
              dispatch(setemployeeAllData(employee));
              // dispatch(setemployeeAllLeaveData(Leaves));
              if (!window.localStorage.getItem("start")) {
                window.localStorage.setItem("presentDay", presentDay);
                window.localStorage.setItem("start", setStart);
              }
              navigate("/dashboard");
            }
            setErrorMessage("");
          }
        } catch (error) {
          setIsLoading(false);
          if (error.response) {
            setErrorMessage(error.response.data.message);
          } else {
            setErrorMessage("Check network.");
          }
        }
      }
    },
    [
      dispatch,
      formData,
      navigate,
      presentDate,
      previousDate,
      validateEmail,
      validatePassword,
    ]
  );

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === "Enter") {
        switch (e.target.name) {
          case "password":
            handleSubmit(e);
            break;
          default:
            focusNextTab(e.target);
            break;
        }
      }
    },
    [handleSubmit]
  );

  const focusNextTab = (currentElement) => {
    const allTabbableElements = Array.from(
      document.querySelectorAll(
        'input, button, textarea, select, a[href], [tabindex]:not([tabindex="-1"])'
      )
    ).filter((element) => !element.disabled && !element.readOnly);
    const currentIndex = allTabbableElements.indexOf(currentElement);
    const nextIndex = (currentIndex + 1) % allTabbableElements.length;
    allTabbableElements[nextIndex].focus();
  };

  useEffect(() => {
    const token = window.localStorage.getItem("Token");
    if (token) {
      navigate("/dashboard");
    } else {
      navigate("/");
    }
  }, [navigate]);

  const handleUserClick = () => {
    setFormData((prevFormData) => ({ ...prevFormData, email: "employee1@jt.com", password: "Employee1@jt.com" }));
  };
  const handleHRClick = () => {
    setFormData((prevFormData) => ({ ...prevFormData, email: "hr1@jt.com", password: "Hr1@jt.com" }));
  };
  const handleAdminClick = () => {
    setFormData((prevFormData) => ({ ...prevFormData, email: "ceo1@jt.com", password: "Ceo1@jt.com" }));
  };
  const demoMode = process.env.REACT_APP_DEMO_MODE === 'true';

  return (
    <MainBox isjtthemedark={is_JT_theme_Dark ? "true" : "false"}>
      {isLoading ? (
        <LoadingBox>
          <Loader />
        </LoadingBox>
      ) : (
        <InnerBoxOne>
          <MainGrid
            container
            item
            isjtthemedark={is_JT_theme_Dark ? "true" : "false"}
          >
            <Grid item sx={12} sm={12} md={12} lg={6}>
              {!isMobile && (
                <InnerBoxTwo isjtthemedark={is_JT_theme_Dark ? "true" : "false"}>
                  <img src={LoginImage} alt="loginImg" className="login-image" />
                </InnerBoxTwo>
              )}
            </Grid>
            <Grid
              item
              sx={12}
              sm={12}
              md={12}
              lg={6}
              style={{
                background: is_JT_theme_Dark
                  ? "var(--darkTheme-before-login-sideblock-bg)"
                  : "var(--lightTheme-before-login-bg)",
              }}
            >
              <LoginBox className="admin-login">
                <Box>
                  <MainFormBox
                    className="admin-form"
                    isjtthemedark={is_JT_theme_Dark ? "true" : "false"}
                  >
                    <BrandLogo
                      className={is_JT_theme_Dark ? "brandLogoDarkThemeclass" : ""}
                      style={{
                        textAlign: "center",
                        maxWidth: "200px",
                        padding: "0 0 0 0",
                        // margin: "0px 0px 0px 20%",
                        display: "block",
                        margin: "auto"
                      }}
                    />
                    <SpaceTypography
                      variant="5"
                      isjtthemedark={is_JT_theme_Dark ? "true" : "false"}
                    >
                      TEAMSPACE
                    </SpaceTypography>
                    <Box
                      component="form"
                      onKeyDown={handleKeyPress}
                      sx={{ marginTop: "30px" }}
                    >
                      <InputBox isjtthemedark={is_JT_theme_Dark ? "true" : "false"}>
                        <TextField
                          inputProps={{ sx: InputFieldProps() }}
                          sx={InputField}
                          name="email"
                          type="email"
                          label="E-mail Address"
                          aria-describedby="emailHelp"
                          placeholder="Enter email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                        {formErrors.email && (
                          <ErrorTypography>{formErrors.email}</ErrorTypography>
                        )}
                      </InputBox>
                      <InputBox isjtthemedark={is_JT_theme_Dark ? "true" : "false"}>
                        <TextField
                          inputProps={{ sx: InputFieldProps() }}
                          sx={InputField}
                          label="Password"
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter Password"
                          value={formData.password}
                          name="password"
                          onChange={handleChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setShowPassword(!showPassword)}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOffIcon
                                      sx={{
                                        color: is_JT_theme_Dark
                                          ? "var(--darkTheme-inputfield-color)"
                                          : "var(--lightTheme-inputfield-color)",
                                      }}
                                    />
                                  ) : (
                                    <VisibilityIcon
                                      sx={{
                                        color: is_JT_theme_Dark
                                          ? "var(--darkTheme-inputfield-color)"
                                          : "var(--lightTheme-inputfield-color)",
                                      }}
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          required
                        />
                        {formErrors.password && (
                          <ErrorTypography>{formErrors.password}</ErrorTypography>
                        )}
                      </InputBox>
                      <ErrorMessageTypography>
                        {errorMessage}
                      </ErrorMessageTypography>{" "}
                      <LoginButton onClick={handleSubmit} variant="contained">
                        Log In
                      </LoginButton>
                    </Box>
                    {/* <Box>
                      <Button variant="contained">Admin</Button>
                      <Button variant="contained" ml={2}>User</Button>
                    </Box> */}
                    <FooterMainBox>
                      <Box sx={{ paddingRight: "8px" }}>
                        <LinkTypography
                          component="a"
                          href="#"
                          onClick={() => navigate("/forgot-password")}
                        >
                          Forgot Password?
                        </LinkTypography>
                      </Box>
                      {!isMobile && (
                        <Box
                          sx={{
                            color: is_JT_theme_Dark
                              ? "var(--darkTheme-inputfield-color)"
                              : "var(--lightTheme-inputfield-color)",
                          }}
                        >
                          |
                        </Box>
                      )}
                      <Box sx={{ paddingLeft: "8px" }}>
                        <LinkTypography
                          component="a"
                          href="#"
                          onClick={() => navigate("/signup")}
                        >
                          Create New Account
                        </LinkTypography>
                      </Box>
                    </FooterMainBox>
                    <TextTypography
                      isjtthemedark={is_JT_theme_Dark ? "true" : "false"}
                    >
                      By using TEAMSPACE, you confirm that you have read and agree
                      to our{" "}
                      <Link
                        className="link"
                        to="/privacy-policy"
                        state={{ from: "notlogIn" }}
                      >
                        Privacy Policy
                      </Link>{" "}
                      and{" "}
                      <Link
                        className="link"
                        to="/privacy-policy"
                        state={{ from: "notlogIn" }}
                      >
                        Term of Service
                      </Link>{" "}
                    </TextTypography>
                  </MainFormBox>
                  {demoMode && (
                    <Card sx={{
                      backgroundColor: is_JT_theme_Dark === true
                        ? "var(--darkTheme-before-login-sideblock-bg)"
                        : "var(--lightTheme-before-login-bg)"
                    }}>
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <Button
                              variant="contained"
                              fullWidth
                              onClick={handleUserClick}
                            >
                              User
                            </Button>
                          </Grid>
                          <Grid item xs={4}>
                            <Button
                              variant="contained"
                              fullWidth
                              onClick={handleHRClick}
                            >
                              HR
                            </Button>
                          </Grid>
                          <Grid item xs={4}>
                            <Button
                              variant="contained"
                              fullWidth
                              onClick={handleAdminClick}
                            >
                              Admin
                            </Button>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  )}
                </Box>
              </LoginBox>
            </Grid>
          </MainGrid>
        </InnerBoxOne>
      )}
    </MainBox>
  );
};

export default Login;
