import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Calendar from "react-calendar";
import { Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

import { fetchConfiguration } from "../../Services/ConfigurationServices";
import "react-calendar/dist/Calendar.css";

function EventCalendar({ festivalData, eventData }) {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);

  const [value, onChange] = useState(new Date());
  const [highlightedSaturdays, setHighlightedSaturdays] = useState([]);

  const eventDates = eventData.map(({ date, eventTitle }) => {
    const [year, month, day] = date.split("-");
    return { date: new Date(year, month - 1, day), eventTitle };
  });

  const publicHolidayDates = festivalData.map(({ date, festival }) => {
    const [year, month, day] = date.split("-");
    return { date: new Date(year, month - 1, day), festival };
  });

  const getSaturdaysData = async () => {
    try {
      const response = await fetchConfiguration();
      if (response.data.success) {
        const { activeSaturdays } = response?.data?.data;
        const currentMonth = value.getMonth();
        const currentYear = value.getFullYear();
        const dates = generateSaturdaysDates(
          activeSaturdays,
          currentYear,
          currentMonth
        );
        setHighlightedSaturdays(dates);
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.error("Failed to fetch Saturdays data:", error);
    }
  };

  const generateSaturdaysDates = (activeSaturdays) => {
    const dates = [];
    for (
      let year = new Date().getFullYear() - 100;
      year <= new Date().getFullYear() + 100;
      year++
    ) {
      for (let month = 0; month < 12; month++) {
        const firstDayOfMonth = new Date(year, month, 1);
        const firstSaturday = new Date(
          year,
          month,
          1 + ((6 - firstDayOfMonth.getDay() + 7) % 7)
        );
        if (firstSaturday.getMonth() === month) {
          if (activeSaturdays["1st Saturday"]) {
            dates.push({ date: firstSaturday, isActive: true });
          } else {
            dates.push({ date: firstSaturday, isActive: false });
          }

          let secondSaturday = new Date(firstSaturday);
          secondSaturday.setDate(firstSaturday.getDate() + 7);
          if (secondSaturday.getMonth() === month) {
            if (activeSaturdays["2nd Saturday"]) {
              dates.push({ date: secondSaturday, isActive: true });
            } else {
              dates.push({ date: secondSaturday, isActive: false });
            }
          }

          let thirdSaturday = new Date(firstSaturday);
          thirdSaturday.setDate(firstSaturday.getDate() + 14);
          if (thirdSaturday.getMonth() === month) {
            if (activeSaturdays["3rd Saturday"]) {
              dates.push({ date: thirdSaturday, isActive: true });
            } else {
              dates.push({ date: thirdSaturday, isActive: false });
            }
          }

          let fourthSaturday = new Date(firstSaturday);
          fourthSaturday.setDate(firstSaturday.getDate() + 21);
          if (fourthSaturday.getMonth() === month) {
            if (activeSaturdays["4th Saturday"]) {
              dates.push({ date: fourthSaturday, isActive: true });
            } else {
              dates.push({ date: fourthSaturday, isActive: false });
            }
          }

          let fifthSaturday = new Date(firstSaturday);
          fifthSaturday.setDate(firstSaturday.getDate() + 28);
          if (fifthSaturday.getMonth() === month) {
            if (activeSaturdays["5th Saturday"]) {
              dates.push({ date: fifthSaturday, isActive: true });
            } else {
              dates.push({ date: fifthSaturday, isActive: false });
            }
          }
        }
      }
    }
    return dates;
  };

  const tileContent = ({ date }) => {
    const eventDate = eventDates.find(
      (event) => event.date.getTime() === date.getTime()
    );
    const publicHolidayDate = publicHolidayDates.find(
      (holiday) => holiday.date.getTime() === date.getTime()
    );
    const satDate = highlightedSaturdays.find(
      (sat) => sat.date.getTime() === date.getTime()
    );

    if (satDate) {
      return (
        <span
          className="std-date"
          style={{
            color: satDate.isActive ? "var(--secondary-text-color)" : "var(--reject-color)",
          }}
        >
          {date.getDate()}
        </span>
      );
    }

    if (eventDate) {
      return (
        <Tooltip TransitionComponent={Zoom} title={eventDate.eventTitle}>
          <span
            className="event-date"
            style={{ color: "#347ccc", fontWeight: "bold" }}
          >
            {date.getDate()}
          </span>
        </Tooltip>
      );
    } else if (publicHolidayDate) {
      return (
        <Tooltip title={publicHolidayDate.festival}>
          <span
            className="public-holiday"
            style={{ color: "#9bc31c", fontWeight: "bold" }}
          >
            {date.getDate()}
          </span>
        </Tooltip>
      );
    } else {
      return null;
    }
  };

  // Function to determine the style of each calendar tile
  const tileClassName = ({ date }) => {
    const eventDate = eventDates.find(
      (event) => event.date.getTime() === date.getTime()
    );
    const publicHolidayDate = publicHolidayDates.find(
      (holiday) => holiday.date.getTime() === date.getTime()
    );
    const Sat2nd4th = highlightedSaturdays.find(
      (sat) => sat.date.getTime() === date.getTime()
    );

    if (Sat2nd4th) {
      return "std-date";
    }

    if (eventDate) {
      return "event-date";
    } else if (publicHolidayDate) {
      return "public-holiday";
    }
  };

  useEffect(() => {
    getSaturdaysData();
    // eslint-disable-next-line
  }, [value]);

  return (
    <Box sx={{ paddingTop: "20px", paddingBottom: "20px" }}>
      <Calendar
        className={
          isDarkTheme ? "DarkThemeEventCalendar" : "LightThemeEventCalendar"
        }
        onChange={onChange}
        value={value}
        tileClassName={tileClassName}
        tileContent={tileContent}
        showNeighboringMonth={false}
      />
    </Box>
  );
}

export default EventCalendar;
